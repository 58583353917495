import React, { useEffect, useState } from "react";
import {
  Layout,
  Typography,
  Segmented,
  Spin,
} from "antd";
import Header from "../../components/Header";
import {loadStripe} from '@stripe/stripe-js';
import { STRIPE_PUBLISHABLE_KEY, useSatelliteEnterpriseContext } from "../../context/SatelliteContext";
import { PricingCard } from "./PricingCard";
import { fetchProducts } from "../../api/productHelpers";
import { addDoc, collection, onSnapshot } from "firebase/firestore";
import { Product } from "../../types/Product";

const { Content } = Layout;
const { Title } = Typography;

export const Subscription = () => {
  const {
    state: { user, products, db },
  } = useSatelliteEnterpriseContext();

  const [isLoading, setIsLoading] = useState(true);
  const [loadingCard, setLoadingCard] = useState('');

  useEffect(() => {
    fetchProducts(db).then(() => {
      if (!user) {
        return;
      }
      if (user?.subscription) {
        setSegment(user.subscription.name === 'Small business' ? 'companies' : 'individuals')
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  }, [user]);

  const basic = products.find(p => p.name === "Basic");
  const premium = products.find(p => p.name === "Premium");
  const smallBusiness = products.find(p => p.name === "Small business");
  const enterprise = products.find(p => p.name === "Enterprise");

  const [segment, setSegment] = useState<string | number>('individuals');


  // Checkout handler
  async function onSubscribe(product: Product) {
    setLoadingCard(product.name);
    const checkoutRef = collection(db, `users/${user.userID}/checkout_sessions`);
    const checkout = await addDoc(checkoutRef, {
      price: product.priceID,
      allow_promotion_codes: true,
      success_url: window.location.origin,
      cancel_url: window.location.origin
    });

    // Wait for the CheckoutSession to get attached by the extension
    onSnapshot(checkout, async (snap) => {
      const { sessionId } = snap.data();
      if (sessionId) {
        // We have a session, let's redirect to Checkout
        const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);
        setIsLoading(false);
        stripe.redirectToCheckout({ sessionId });
      }
    });
  }

  if (isLoading) {
    return (
      <div style={{display: "flex", alignItems: "center", height: "100vh", justifyContent: "center"}}>
        <Spin tip="Loading" size="large"/>
      </div>
    );
  }

  return (
    <div style={styles.background}>
      <Header />
      <Content style={styles.flex}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
            marginBottom: "5%",
          }}
        >
          <Title level={1} style={{ color: "white" }}>
            Subscription
          </Title>
          <div>
            <Segmented
              size="large"
              value={segment}
              onChange={setSegment}
              options={[
                {
                  label: <div style={{ color: "black" }}>For individuals</div>,
                  value: "individuals",
                },
                {
                  label: "For companies",
                  value: "companies",
                },
              ]}
            />
          </div>
        </div>
        {segment === "individuals" ?
        <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
          <PricingCard
            price={basic?.price}
            isActive={user.subscription?.name === "Basic"}
            isDisabled={!basic?.active}
            title={basic?.name}
            onSelect={() => onSubscribe(basic)}
            isLoading={loadingCard === "Basic"}
            description={basic?.description}
            features={[
              "Attend unlimited events",
              "Access to all activities",
              "Built-in scheduling",
            ]}
          />
          <PricingCard
            price={premium?.price}
            isActive={user.subscription?.name === "Premium"}
            isDisabled={!premium?.active}
            title={premium?.name}
            onSelect={() => onSubscribe(premium)}
            isLoading={loadingCard === "Premium"}
            description={premium?.description}
            features={[
              "Exclusive VIP events",
              "Create friend lists",
              "Venue discounts",
            ]}
          />
        </div>
        : 
        <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
          <PricingCard
            price={smallBusiness?.price}
            isActive={user.subscription?.name === "Small business"}
            isDisabled={!smallBusiness?.active}
            title={smallBusiness?.name}
            isLoading={loadingCard === "Small business"}
            onSelect={() => onSubscribe(smallBusiness)}
            description={smallBusiness?.description}
            features={[
              "Accounts for up to 50 employees",
              "Create and manage private events",
              "Technical support via Slack",
            ]}
          />
          <PricingCard
            price={enterprise?.price}
            isActive={user.subscription?.name === "Enterprise"}
            isDisabled={!enterprise?.active}
            title={enterprise?.name}
            isLoading={loadingCard === "Enterprise"}
            onSelect={() => onSubscribe(enterprise)}
            description={enterprise?.description}
            features={[
              "Unlimited accounts",
              "Data insights on employee activity",
              "Tech support + feature requests",
            ]}
          />
          </div>
        }
      </Content>
    </div>
  );
};
export default Subscription;

const styles = {
  flex: {
    paddingLeft: "10%",
    paddingTop: "5%",
    paddingRight: "10%",
    height: "100%",
  },
  background: {
    backgroundColor: "#2196f3",
    height: "100vh",
    overflow: "hidden",
    backgroundImage: "linear-gradient(160deg, #2196f3 0%, #90caf9 100%)",
  },
};
