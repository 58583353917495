import { Modal, Typography, Button, Input } from "antd";
import React, { useEffect, useState } from "react";
import { FETCH_CITIES, UPDATE_ACTIVE_CITY, useSatelliteEnterpriseContext } from "../../../context/SatelliteContext";
import { City } from "../../../types/City";
import { activateCity, deactivateCity, updateCityInDB } from "../../../api/cityHelpers";
import { addActivityToCity } from "../../../api/activityHelpers";
import { fetchCities } from "../../../actions/cityActions";

const { Text, Title } = Typography;

interface CityModalProps {
  isOpen: boolean;
  onCancel: () =>  void;
}

export const CityModal = (props: CityModalProps) => {
  const { isOpen, onCancel } = props;
  const { state: { activeCity, db, user, activities }, dispatch } = useSatelliteEnterpriseContext();

  const activitiesForCity = activities.filter(a => a.cityID === activeCity?.cityID)

  const [newActivity, setNewActivity] = useState(null);
  const [activitiesToSave, setActivitiesToSave] = useState([]);

  useEffect(() => {
    setActivitiesToSave([]);
    setNewActivity(null);
  }, [activeCity]);

  const createCity = () => {
    if (activeCity && user) {
      const newCity: City = {
        ...activeCity,
        cityID: activeCity.name.replace(/ /g, "_").toLowerCase(),
        isActive: false,
      }
      updateCityInDB(db, newCity).then(async () => {
        await Promise.all(activitiesToSave.map(async (a) => {
          return await addActivityToCity(db, a, activeCity.name.replace(/ /g, "_").toLowerCase());
        })).then(async (_) => {
          const citiesPayload = await fetchCities(db);
          dispatch(citiesPayload);
          onCancel();
        })
      });
    } else {
      console.log("No city found.")
    }
  }

  const updateCity = () => {
    if (activeCity && user) {
      updateCityInDB(db, activeCity).then(async () => {
        Promise.all(activitiesToSave.map(async (a) => {
          return await addActivityToCity(db, a, activeCity?.cityID);
        })).then(async (response) => {
          const citiesPayload = await fetchCities(db);
          dispatch({cities: citiesPayload, type: FETCH_CITIES});
          onCancel();
        })
      });
    } else {
      console.log("No city found.")
    }
  }

  const onSaveChanges = () => {
    activeCity?.cityID ? updateCity() : createCity();
  }

  const handleAddActivityClick = () => {
    setActivitiesToSave([...activitiesToSave, newActivity]);
    setNewActivity(null);
  }

  const onToggleActivation = () => {
    if (!activeCity || !user) {
      return;
    }
    if (activeCity.isActive) {
      deactivateCity(db, activeCity).then(async () => {
        const citiesPayload = await fetchCities(db);
        dispatch({cities: citiesPayload, type: FETCH_CITIES});
        onCancel();
      })
    } else {
      activateCity(db, activeCity).then(async () => {
        const citiesPayload = await fetchCities(db);
        dispatch({cities: citiesPayload, type: FETCH_CITIES});
        onCancel();
      })
    }
  }

  const updateActiveCityValue = (key: string, value: any) => {
    dispatch({
      type: UPDATE_ACTIVE_CITY,
      activeCity: { ...activeCity, [key]: value },
    });
  };

  return (
    <Modal
      title={activeCity?.cityID ? "Edit city" : "Add new city"}
      open={isOpen}
      width={800}
      cancelText={"Cancel"}
      onCancel={onCancel}
      onOk={onSaveChanges}
      okText={"Save changes"}
    >
      <>
        <div style={{display: "flex", flexDirection: "row"}}>
          <div>
            <Title>{activeCity?.name}</Title>
            <Text>City name:</Text>
            <Input
              value={activeCity?.name}
              onChange={(e) => updateActiveCityValue("name", e.target.value)}
              style={{ marginBottom: 10 }}
              placeholder="Los Angeles, New York City, etc."
            />
          </div>
          <div style={{marginLeft: 30, marginRight: 30, height: 200, overflowY: "scroll"}}>
            {
              [...activitiesToSave, ...activitiesForCity].map(a => (
                <div key={a.name} style={{marginBottom: 10, width: "100%", display: "flex", justifyContent: "space-between"}}>
                  <Text>{a?.name} {a?.emoji}</Text>
                  <Button style={{marginLeft: 20}}>Remove</Button>
                </div>
              ))
            }
          </div>
          <div style={{width: 150}}>
          <Input
              value={newActivity?.name}
              onChange={(e) => setNewActivity({...newActivity, name: e.target.value})}
              style={{ marginBottom: 10 }}
              placeholder="Movies, hiking, etc."
            />
            <Input
              value={newActivity?.activityID}
              onChange={(e) => setNewActivity({...newActivity, activityID: e.target.value})}
              style={{ marginBottom: 10 }}
              placeholder="night-at-the-movies, hike"
            />
            <Input
              value={newActivity?.emoji}
              onChange={(e) => setNewActivity({...newActivity, emoji: e.target.value})}
              style={{ marginBottom: 10 }}
              placeholder="🍎, etc."
            />
          <Button onClick={handleAddActivityClick} disabled={!newActivity}>Add activity</Button>
          </div>

        </div>
        {activeCity?.cityID && <Button danger onClick={onToggleActivation}>{activeCity?.isActive ? "Deactivate city" : "Activate city"}</Button>}
      </>
    </Modal>
  );
}

export default CityModal;