import { Firestore, doc, setDoc } from "firebase/firestore";
import { readEvents } from "../api/scheduledEventHelpers";
import {
  ADD_EVENT,
  DispatchPayload,
  FETCH_EVENTS,
  UPDATE_ACTIVE_EVENT,
  UPDATE_EVENT,
} from "../context/SatelliteContext";
import { ScheduledEvent } from "../types/Event";

export const fetchEvents = async (
  db: Firestore,
  userID: string
): Promise<DispatchPayload> => {
  return await readEvents(db, userID)
    .then((events) => {
      return { type: FETCH_EVENTS, events } as DispatchPayload;
    })
    .catch((_) => {
      return { type: FETCH_EVENTS, events: [] };
    });
};

export const updateActiveEventValue = (
  activeEvent: ScheduledEvent,
  dispatch: (payload: DispatchPayload) => void,
  key: string,
  value: any
) => {
  dispatch({
    type: UPDATE_ACTIVE_EVENT,
    activeEvent: { ...activeEvent, [key]: value },
  });
};

export const updateActiveEvent = (
  activeEvent: ScheduledEvent,
  dispatch: (payload: DispatchPayload) => void
) => {
  dispatch({
    type: UPDATE_ACTIVE_EVENT,
    activeEvent,
  });
};

export const updateEventInDB = async (
  db: Firestore,
  activeEvent: ScheduledEvent,
  dispatch: (payload: DispatchPayload) => void,
  isNewEvent: boolean
) => {
  await setDoc(
    doc(db, "scheduledEvents", activeEvent.eventID),
    activeEvent
  ).then(() => {
    if (isNewEvent) {
      dispatch({ type: ADD_EVENT, event: activeEvent });
    } else {
      dispatch({ type: UPDATE_EVENT, event: activeEvent})
    }
  });
};
