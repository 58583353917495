import React, { useEffect, useState } from "react";
import { Button, Layout, Spin, Typography } from "antd";
import {
  FETCH_EVENTS,
  UPDATE_ACTIVE_VENUE,
  useSatelliteEnterpriseContext,
} from "../../context/SatelliteContext";
import Header from "../../components/Header";
//@ts-ignore
import img from "../../assets/beach.jpeg";
import { BORDER_RADIUS, SPACING } from "../../configs/theme";
import EventModal from "./Modal";
import { ScheduledEvent } from "../../types/Event";
import { EventsTable } from "./Table";
import { fetchEvents } from "../../actions/eventActions";

const { Content } = Layout;
const { Title } = Typography;

export const Events = () => {
  const {
    state: { user, db, events },
    dispatch,
  } = useSatelliteEnterpriseContext();

  useEffect(() => {
    if (user) {
      fetchEvents(db, user?.userID).then((events) => {
        dispatch(events)
      });
    }
  }, [user?.userID])

  const [isEventModalOpen, setIsEventModalOpen] = useState<boolean>(false);

  const onCreateEventButtonClick = () => {
    dispatch({ type: UPDATE_ACTIVE_VENUE, activeVenue: undefined });
    setIsEventModalOpen(true);
  };

  const onEventClick = (record: ScheduledEvent) => {
    dispatch({ type: UPDATE_ACTIVE_VENUE, activeVenue: record });
    setIsEventModalOpen(true);
  }

  if (!user) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "100vh",
          justifyContent: "center",
        }}
      >
        <Spin tip="Loading" size="large" />
      </div>
    );
  }

  return (
    //@ts-ignore
    <div style={styles.container}>
      <Header />
      <div
        style={{ ...styles.background, ...{ backgroundImage: `url(${img})` } }}
      >
        <Content style={styles.content}>
          <div style={styles.titleBox}>
            <Title level={2}>Events</Title>
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={onCreateEventButtonClick}
            >
              Create an event
            </Button>
          </div>
          <EventModal isOpen={isEventModalOpen} onCancel={() => setIsEventModalOpen(false)}/>
          <EventsTable events={events} onEventClick={onEventClick} />
        </Content>
      </div>
    </div>
  );
};
export default Events;

const styles = {
  container: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  background: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    flex: 1,
  },
  content: {
    margin: SPACING.MD,
    borderRadius: BORDER_RADIUS.ROUNDED,
    padding: SPACING.MD,
    backgroundColor: "white",
  },
  titleBox: {
    display: "flex",
    alignItems: "center",
    marginBottom: SPACING.MD,
    justifyContent: "space-between",
  },
};
