import { Firestore, collection, getDocs, query, where } from "firebase/firestore";
import { ScheduledEvent } from "../types/Event";

export const readEvents = async (
  db: Firestore,
  userID: string
): Promise<ScheduledEvent[]> => {
  if (userID === null) {
    return [];
  }
  const response = query(
    collection(db, `scheduledEvents`),
    where("createdByUserID", "==", userID)
  );
  const documentSnapshots = await getDocs(response);
  return documentSnapshots.docs.map((e) => e.data() as ScheduledEvent);
};