import React, { createContext, useReducer, useContext } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, Firestore } from "firebase/firestore";
import { Database, getDatabase } from "firebase/database";
import { productionConfig } from "../configs/production";
import { Venue } from "../types/Venue";
import { Activity } from "../types/Activity";
import { developmentConfig } from "../configs/development";
import { Product } from "../types/Product";
import { ScheduledEvent } from "../types/Event";
import { PrivateUser } from "../types/User";
import { City } from "../types/City";
import { Neighborhood } from "../types/Neighborhood";

const isLocalHost = Boolean(window.location.hostname === 'localhost')

// Initialize Firebase
const app = initializeApp(isLocalHost ? developmentConfig : productionConfig);

// @ts-ignore
export const SatelliteEnterpriseContext = createContext();

type State = {
  user?: PrivateUser;
  db: Firestore;
  activeVenue?: Venue;
  activeEvent?: ScheduledEvent;
  activeCity?: City;
  venues: Array<Venue>;
  realtimeDB: Database;
  activities: Activity[];
  products: Product[];
  cities: City[];
  neighborhoods: Neighborhood[];
  events: ScheduledEvent[];
  isLocalHost: boolean;
};

export interface DispatchPayload {
  type: string;
  user?: PrivateUser;
  isVerified?: boolean;
  isInvalid?: boolean;
  cities?: City[];
  activities?: Activity[];
  venues?: Venue[];
  events?: ScheduledEvent[];
  products?: Product[];
  neighborhoods?: Neighborhood[];
  activeVenue?: Venue;
  activeEvent?: ScheduledEvent;
  activeCity?: City;
  event?: ScheduledEvent;
};

// Actions
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const UPDATE_ACTIVE_VENUE = "UPDATE_ACTIVE_VENUE";
export const UPDATE_ACTIVE_EVENT = "UPDATE_ACTIVE_EVENT";
export const UPDATE_ACTIVE_CITY = "UPDATE_ACTIVE_CITY";
export const UPDATE_USER_INFORMATION = "UPDATE_USER_INFORMATION";
export const FETCH_VENUES = "FETCH_VENUES";
export const FETCH_ACTIVITIES = "FETCH_ACTIVITIES";
export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FETCH_EVENTS = "FETCH_EVENTS";
export const FETCH_CITIES = "FETCH_CITIES";
export const FETCH_NEIGHBORHOODS = "FETCH_NEIGHBORHOODS";

export const ADD_EVENT = "ADD_EVENT";

export const STRIPE_PUBLISHABLE_KEY = "pk_live_51N3MrDEe8pJvM10mFuG3Kt2M5xbmJxjk1whj2VcSVlgLSHeW4n8kzoqGROC21QpViJxed8Kzl3kXaTBKIDIHvloV00U8KbTuyg";

// Initial state
const initialState: State = {
  db: getFirestore(),
  realtimeDB: getDatabase(),
  venues: [],
  activeVenue: undefined,
  activeEvent: undefined,
  user: null,
  activities: [],
  cities: [],
  events: [],
  neighborhoods: [],
  products: [],
  isLocalHost
};

// Reducer
export const satelliteEnterpriseReducer = (
  state: State,
  action: DispatchPayload
) => {
  switch (action.type) {

    // handle user actions
    case LOGIN:
    case UPDATE_USER_INFORMATION:
      return {
        ...state,
        user: action.user,
      };
    case LOGOUT:
      return {
        ...state,
        user: null,
      };
    case FETCH_ACTIVITIES:
      return {
        ...state,
        activities: action.activities,
      }
    case FETCH_VENUES:
      return {
        ...state,
        venues: action.venues,
      }
    case FETCH_EVENTS:
      return {
        ...state,
        events: action.events,
      }
    case ADD_EVENT:
      return {
        ...state,
        events: [...state.events, action.event]
      }
    case UPDATE_EVENT:
      return {
        ...state,
        events: state.events.map(e => {
          if (e.eventID !== action.event.eventID) {
            return e;
          } else {
            return action.event;
          }
        })
      }
    case FETCH_PRODUCTS:
      return {
        ...state,
        products: action.products,
      }
    case FETCH_CITIES:
      return {
        ...state,
        cities: action.cities,
      }
    case FETCH_NEIGHBORHOODS:
      return {
        ...state,
        neighborhoods: action.neighborhoods,
      }
    case UPDATE_ACTIVE_VENUE:
      return {
        ...state,
        activeVenue: action.activeVenue,
      }
    case UPDATE_ACTIVE_EVENT:
      return {
        ...state,
        activeEvent: action.activeEvent,
      }
    case UPDATE_ACTIVE_CITY:
      return {
        ...state,
        activeCity: action.activeCity,
      }
    default:
      return state;
  }
};

function SatelliteEnterpriseProvider(
  props: JSX.IntrinsicAttributes & React.ProviderProps<unknown>
) {
  // @ts-ignore
  const [state, dispatch] = useReducer(satelliteEnterpriseReducer, initialState);

  const satelliteData = { state, dispatch };
  // @ts-ignore
  return <SatelliteEnterpriseContext.Provider value={satelliteData} {...props} />;
}

const useSatelliteEnterpriseContext = () => {
  return useContext(SatelliteEnterpriseContext) as {
    state: State;
    dispatch: (DispatchPayload) => void;
  };
};

export { SatelliteEnterpriseProvider, useSatelliteEnterpriseContext };
