import React from 'react';
import { Table } from "antd";
import { ScheduledEvent } from '../../../types/Event';
import { columns } from './Columns';

interface EventsTableProps {
  events: ScheduledEvent[];
  onEventClick: (record: ScheduledEvent) => void;
};

export const EventsTable = ({events, onEventClick}: EventsTableProps) => {
  return (
    <Table
      columns={columns}
      dataSource={events.map(e => {
        return {
          ...e,
          key: e.eventID
        }
      })}
      pagination={{ pageSize: 5 }}
      rowKey={(record) => record.venueID}
      onRow={(record, _) => {
        return {
          onClick: () => onEventClick(record),
        };
      }}
    />
  );
};
