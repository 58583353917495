import {
  arrayUnion,
  collection,
  doc,
  Firestore,
  getDocs,
  orderBy,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { City } from "../types/City";
import { Neighborhood } from "../types/Neighborhood";

export const readCities = async (db: Firestore): Promise<City[]> => {
  const response = query(collection(db, `cities`));
  const documentSnapshots = await getDocs(response);
  return documentSnapshots.docs.map((c) => c.data() as City);
};

export const updateCityInDB = async (db: Firestore, city: City) => {
  try {
    await setDoc(doc(db, "cities", city.cityID), {
      ...city,
    });
  } catch (e) {
    console.log(e)
  }
}

export const deactivateCity = async (db: Firestore, city: City) => {
  try {
    await setDoc(doc(db, "cities", city.cityID), {
      ...city,
      isActive: false,
    });
  } catch (e) {
    console.log(e)
  }
}

export const activateCity = async (db: Firestore, city: City) => {
  try {
    await setDoc(doc(db, "cities", city.cityID), {
      ...city,
      isActive: true,
    });
  } catch (e) {
    console.log(e)
  }
}

export const addEmailToWaitlist = async (db: Firestore, email: string) => {
  try {
    await updateDoc(doc(db, "cities", "los_angeles"), {
      waitlist: arrayUnion(email),
    });
  } catch (e) {
    console.log(e);
  }
};
