import React, { useState } from "react";
import { Input, Layout, Typography, Button } from "antd";
import Header from "../../../components/Header";
import { useSatelliteEnterpriseContext } from "../../../context/SatelliteContext";
import { Redirect } from "react-router-dom";
import { collectionGroup, deleteDoc, getDocs, query, where } from "firebase/firestore";

const { Content } = Layout;
const { Title, Text } = Typography;

const deleteEventsWithUser = async (db, userID) => {
  const potentialEventsToDelete = query(collectionGroup(db, 'potentialEvents'), where('userIDs', 'array-contains', userID));
  const potentialSnapshot = await getDocs(potentialEventsToDelete);
  potentialSnapshot.forEach((doc) => {
    deleteDoc(doc.ref)
  });

  const activeEventsToDelete = query(collectionGroup(db, 'potentialEvents'), where('userIDs', 'array-contains', userID));
  const activeSnapshot = await getDocs(activeEventsToDelete);
  activeSnapshot.forEach((doc) => {
    deleteDoc(doc.ref)
  });
}

export const Admin = () => {
  const { state: { db, user }} = useSatelliteEnterpriseContext();
  const [deletedUserID, setDeletedUserID] = useState(null);
  if (!user.isAdmin) {
    return <Redirect to="/" />
  }

  return (
    <div>
      <Header isLoggedIn={!!user}/>
      <Content style={{ padding: "50px", height: "100%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
          <div style={{backgroundColor: "white", borderRadius: 10, padding: 20, paddingTop: 10}}>
            <Title>Clean up events 🧹</Title>
            <div style={{marginBottom: 10}}>
            <Input placeholder="Enter deleted userID here..." value={deletedUserID} onChange={(e) => setDeletedUserID(e.target.value)}/>
            </div>
            <Button onClick={() => deleteEventsWithUser(db, deletedUserID)}>Clean up</Button>
          </div>
      </Content>
    </div>
  )
}


export default Admin;