import React from 'react';
import { useSatelliteEnterpriseContext } from '../../../context/SatelliteContext';
import { Input, InputNumber, Typography } from 'antd';
import { SPACING } from '../../../configs/theme';
import { updateActiveEventValue } from '../../../actions/eventActions';

export const Cost = () => {
  const { state: { activeEvent, venues }, dispatch } = useSatelliteEnterpriseContext();

  const onChange = () => {

  }

  return (
    <div style={{display: "flex", flexDirection: "row"}}>
      <div style={{display: "flex", flexDirection: "column", width: "30%"}}>
    <Typography.Text style={{fontWeight: "bold" }}>Ticket cost:</Typography.Text>
    <InputNumber
      style={{marginBottom: SPACING.SM}}
      defaultValue={activeEvent?.externalCost?.toString()}
      formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
      onChange={(e) => updateActiveEventValue(activeEvent, dispatch, "externalCost", e)}
    />
    </div>
    <div style={{display: "flex", flexDirection: "column", width: "70%"}}>

    <Typography.Text style={{fontWeight: "bold" }}>Ticket purchase link:</Typography.Text>
    <Input
      value={activeEvent?.externalCostLink}
      onChange={(e) => updateActiveEventValue(activeEvent, dispatch, "externalCostLink", e.target.value)}
      style={{ marginBottom: SPACING.SM }}
      placeholder="ticketmaster.com/test, etc."
    />
    </div>
  </div>
  )
}