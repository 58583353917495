import React from "react";
import { ColumnsType } from "antd/es/table";
import { Typography } from "antd";
import { ScheduledEvent } from "../../../types/Event";
import { AVATAR_SIZE } from "../../../configs/theme";

const { Text } = Typography;

export const columns: ColumnsType<ScheduledEvent> = [
  {
    title: "Image",
    dataIndex: "imageURL",
    render: (image) => <img src={image} height={AVATAR_SIZE.MD}/>
  },
  {
    title: "Name",
    dataIndex: "name",
  },
];
