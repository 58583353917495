import React from "react";
import Routes from "./routes";
import { SatelliteEnterpriseProvider } from "./context/SatelliteContext";
import { ConfigProvider } from "antd";

export const App = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#2196f3",
          fontFamily: "Lato, sans-serif",
        },
      }}
    >
      <SatelliteEnterpriseProvider>
        <Routes />
      </SatelliteEnterpriseProvider>
    </ConfigProvider>
  );
};

export default App;
