import React from "react";
import { ColumnsType } from "antd/es/table";
import { Typography } from "antd";
import { Venue } from "../../types/Venue";
import { Activity } from "../../types/Activity";
import {
  AVATAR_SIZE,
  BORDER_RADIUS,
  FONT_SIZE,
  SPACING,
} from "../../configs/theme";

const { Text } = Typography;

export const columns: ColumnsType<Venue> = [
  {
    title: "Image",
    dataIndex: "imageURL",
    render: (i) => {
      return (
        <img
          height={AVATAR_SIZE.MD}
          width={AVATAR_SIZE.MD}
          style={{ borderRadius: BORDER_RADIUS.CIRCLE }}
          src={i}
          alt={i}
        />
      );
    },
  },
  {
    title: "Name",
    dataIndex: "name",
    sorter: (a, b) => a.name.length - b.name.length,
    sortDirections: ["descend"],
    render: (i) => {
      return (
        <Text style={{ fontWeight: "bold", fontSize: FONT_SIZE.MD }}>{i}</Text>
      );
    },
  },
  {
    title: "Address",
    dataIndex: "address",
    render: (address) => {
      return <Text style={{ fontSize: FONT_SIZE.MD }}>{address}</Text>;
    },
  },
  {
    title: "Activities",
    dataIndex: "hydratedActivities",
    render: (activities: Activity[]) => {
      return (
        <div
          key="hydratedActivities"
          style={{ display: "flex", flexDirection: "column" }}
        >
          {activities.map((a) => (
            <Text
              style={{ marginTop: SPACING.SM, fontSize: FONT_SIZE.MD }}
              key={a.activityID}
            >
              {a.name} {a.emoji}
            </Text>
          ))}
        </div>
      );
    },
  },
];
