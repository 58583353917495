import { Input, Typography } from "antd";
import React from "react";
import { updateActiveEventValue } from "../../../actions/eventActions";
import { useSatelliteEnterpriseContext } from "../../../context/SatelliteContext";
import { SPACING } from "../../../configs/theme";

const { Text } = Typography;
const { TextArea } = Input;

export const Description = () => {
  const {
    state: { activeEvent },
    dispatch,
  } = useSatelliteEnterpriseContext();
  return (
    <div>
      <Text style={{ fontWeight: "bold" }}>Description:</Text>
      <TextArea
        onChange={(e) =>
          updateActiveEventValue(activeEvent, dispatch, "description", e.target.value)
        }
        value={activeEvent?.description}
        rows={4}
        style={{ marginBottom: SPACING.SM }}
        placeholder="Describe your venue here..."
      />
    </div>
  );
};

export default Description;
