import React from "react";
import { Layout, Typography } from "antd";
import Header from "../../components/Header";
import { useSatelliteEnterpriseContext } from "../../context/SatelliteContext";

const { Content } = Layout;
const { Title, Text } = Typography;

export const Privacy = () => {
  const {
    state: { user },
  } = useSatelliteEnterpriseContext();
  return (
    <div style={styles.background}>
      <Header isLoggedIn={!!user} />
      <Content style={{ ...styles.content }}>
        <div
          style={{
            borderWidth: 2,
            borderColor: "black",
            backgroundColor: "white",
            height: "100%",
            width: "90%",
            margin: 50,
            borderRadius: 40,
            padding: 20,
          }}
        >
          <h1>Privacy Policy</h1>
          <h3>Last updated July 17, 2023</h3>
          <p>
            This privacy notice for{" "}
            <strong>Satellite Friends Foundation</strong> ("we," "us," or
            "our"), describes how and why we might collect, store, use, and/or
            share ("process") your information when you use our services
            ("Services"), such as when you:
            <ul>
              * Download and use our mobile application (Satellite: Friends), or
              any other application of ours that links to this privacy notice.
            </ul>
            <ul>
              * Engage with us in other related ways, including any sales,
              marketing, or events
            </ul>
          </p>
          <strong>Questions or concerns?</strong>
          <p>
            Reading this privacy notice will help you understand your privacy
            rights and choices. If you do not agree with our policies and
            practices, please do not use our Services. If you have additional
            questions, send an email to will@satellitefriends.com.
          </p>
          <h2>Summary of key points</h2>
          <strong>What personal information do we process?</strong>
          <p>
            When you visit, use, or navigate our Services, we may process
            personal information depending on how you interact with us and the
            Services, the choices you make, and the products and features you
            use.
          </p>
          <strong>Do we process any sensitive personal information?</strong>
          <p>We do not process sensitive personal information.</p>
          <strong>Do we receive any information from third parties?</strong>
          <p> We do not receive any information from third parties. </p>
          <strong>How do we process your information? </strong>
          <p>
            We process your information to provide, improve, and administer our
            Services, communicate with you, for security and fraud prevention,
            and to comply with law. We may also process your information for
            other purposes with your consent. We process your information only
            when we have a valid legal reason to do so.
          </p>
          <strong>
            In what situations and with which parties do we share personal
            information?
          </strong>
          <p>
            We may share information in specific situations and with specific
            third parties.
          </p>
          <strong>How do we keep your information safe? </strong>
          <p>
            We have organizational and technical processes and procedures in
            place to protect your personal information. However, no electronic
            transmission over the internet or information storage technology can
            be guaranteed to be 100% secure, so we cannot promise or guarantee
            that hackers, cybercriminals, or other unauthorized third parties
            will not be able to defeat our security and improperly collect,
            access, steal, or modify your information.
          </p>
          <strong>What are your rights? </strong>
          <p>
            Depending on where you are located geographically, the applicable
            privacy law may mean you have certain rights regarding your personal
            information. Learn more about your privacy rights.
          </p>
          <strong>How do you exercise your rights? </strong>
          <p>
            The easiest way to exercise your rights is by submitting a data
            subject access request, or by contacting us. We will consider and
            act upon any request in accordance with applicable data protection
            laws.
          </p>
          <h2 id="what_information_do_we_collect">
            1. What information do we collect?
          </h2>
          <h3>Personal information you disclose to us</h3>
          <strong>In Short:</strong>{" "}
          <i>We collect personal information that you provide to us.</i>
          <p>
            We collect personal information that you voluntarily provide to us
            when you register on the Services, express an interest in obtaining
            information about us or our products and Services, when you
            participate in activities on the Services, or otherwise when you
            contact us.
          </p>
          <p>
            <strong>Personal Information Provided by You.</strong> The personal
            information that we collect depends on the context of your
            interactions with us and the Services, the choices you make, and the
            products and features you use. The personal information we collect
            may include the following:
            <ul>
              <li>names</li>
              <li>email</li>
              <li>job titles</li>
              <li>usernames</li>
              <li>passwords</li>
              <li>debit/credit card numbers</li>
            </ul>
            <p>
              <strong>Sensitive Information.</strong> We do not process
              sensitive information.
            </p>
            <p>
              <strong>Social Media Login Data.</strong> We may provide you with
              the option to register with us using your existing social media
              account details, like your Facebook, Twitter, or other social
              media account. If you choose to register in this way, we will
              collect the information described in the section called{" "}
              <a href="#how_do_we_handle_your_social_logins">
                How do we handle your social logins?
              </a>{" "}
              below.
            </p>
          </p>
          <p>
            <strong>Application Data.</strong> If you use our application(s), we
            also may collect the following information if you choose to provide
            us with access or permission:
            <ul>
              <li>
                <i>Geolocation Information.</i> We may request access or
                permission to track location-based information from your mobile
                device, either continuously or while you are using our mobile
                application(s), to provide certain location-based services. If
                you wish to change our access or permissions, you may do so in
                your device's settings.
              </li>
              <li>
                <i>Mobile Device Data.</i> We automatically collect device
                information (such as your mobile device ID, model, and
                manufacturer), operating system, version information and system
                configuration information, device and application identification
                numbers, browser type and version, hardware model Internet
                service provider and/or mobile carrier, and Internet Protocol
                (IP) address (or proxy server). If you are using our
                application(s), we may also collect information about the phone
                network associated with your mobile device, your mobile device's
                operating system or platform, the type of mobile device you use,
                your mobile device's unique device ID, and information about the
                features of our application(s) you accessed.
              </li>
              <li>
                <i>Push Notifications.</i> We may request to send you push
                notifications regarding your account or certain features of the
                application(s). If you wish to opt out from receiving these
                types of communications, you may turn them off in your device's
                settings.
              </li>
            </ul>
            This information is primarily needed to maintain the security and
            operation of our application(s), for troubleshooting, and for our
            internal analytics and reporting purposes. All personal information
            that you provide to us must be true, complete, and accurate, and you
            must notify us of any changes to such personal information.
          </p>
          <h3>Information automatically collected</h3>
          <p>
            <strong>In Short:</strong>{" "}
            <i>
              Some information such as your Internet Protocol (IP) address
              and/or browser and device characteristics — is collected
              automatically when you visit our Services.
            </i>
            <p>
              {" "}
              We automatically collect certain information when you visit, use,
              or navigate the Services. This information does not reveal your
              specific identity (like your name or contact information) but may
              include device and usage information, such as your IP address,
              browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about how and when you use our Services, and other
              technical information. This information is primarily needed to
              maintain the security and operation of our Services, and for our
              internal analytics and reporting purposes.
            </p>
            <p>
              Like many businesses, we also collect information through cookies
              and similar technologies. The information we collect includes:
            </p>
            <ul>
              <li>
                <i>Log and Usage Data.</i> Log and usage data is
                service-related, diagnostic, usage, and performance information
                our servers automatically collect when you access or use our
                Services and which we record in log files. Depending on how you
                interact with us, this log data may include your IP address,
                device information, browser type, and settings and information
                about your activity in the Services (such as the date/time
                stamps associated with your usage, pages and files viewed,
                searches, and other actions you take such as which features you
                use), device event information (such as system activity, error
                reports (sometimes called "crash dumps"), and hardware
                settings).
              </li>
              <li>
                <i>Device Data.</i> We collect device data such as information
                about your computer, phone, tablet, or other device you use to
                access the Services. Depending on the device used, this device
                data may include information such as your IP address (or proxy
                server), device and application identification numbers,
                location, browser type, hardware model, Internet service
                provider and/or mobile carrier, operating system, and system
                configuration information.
              </li>
              <li>
                <i>Location Data.</i> We collect location data such as
                information about your device's location, which can be either
                precise or imprecise. How much information we collect depends on
                the type and settings of the device you use to access the
                Services. For example, we may use GPS and other technologies to
                collect geolocation data that tells us your current location
                (based on your IP address). You can opt out of allowing us to
                collect this information either by refusing access to the
                information or by disabling your Location setting on your
                device. However, if you choose to opt out, you may not be able
                to use certain aspects of the Services.
              </li>
            </ul>
          </p>
          <h2 id="how_do_we_process_your_information">
            2. How do we process your information?
          </h2>
          <p>
            <strong>In Short:</strong>{" "}
            <i>
              We process your information to provide, improve, and administer
              our Services, communicate with you, for security and fraud
              prevention, and to comply with law. We may also process your
              information for other purposes with your consent.
            </i>
            <p>
              We process your personal information for a variety of reasons,
              depending on how you interact with our Services, including:
            </p>
            <ul>
              <li>
                <strong>
                  To facilitate account creation and authentication and
                  otherwise manage user accounts.
                </strong>{" "}
                We may process your information so you can create and log in to
                your account, as well as keep your account in working order.
              </li>
              <li>
                <strong>
                  To deliver and facilitate delivery of services to the user.
                </strong>{" "}
                We may process your information to provide you with the
                requested service.
              </li>
              <li>
                <strong>
                  To respond to user inquiries/offer support to users.
                </strong>{" "}
                We may process your information to respond to your inquiries and
                solve any potential issues you might have with the requested
                service.
              </li>
              <li>
                <strong>To send administrative information to you.</strong> We
                may process your information to send you details about our
                products and services, changes to our terms and policies, and
                other similar information.
              </li>
              <li>
                <strong>To enable user-to-user communications.</strong> We may
                process your information if you choose to use any of our
                offerings that allow for communication with another user.
              </li>
              <li>
                <strong>To request feedback.</strong> We may process your
                information when necessary to request feedback and to contact
                you about your use of our Services.
              </li>
              <li>
                <strong>
                  To evaluate and improve our Services, products, marketing, and
                  your experience.
                </strong>{" "}
                We may process your information when we believe it is necessary
                to identify usage trends, determine the effectiveness of our
                promotional campaigns, and to evaluate and improve our Services,
                products, marketing, and your experience.
              </li>
              <li>
                <strong>To identify usage trends.</strong> We may process
                information about how you use our Services to better understand
                how they are being used so we can improve them.
              </li>
              <li>
                <strong>
                  To determine the effectiveness of our marketing and
                  promotional campaigns.
                </strong>{" "}
                We may process your information to better understand how to
                provide marketing and promotional campaigns that are most
                relevant to you.
              </li>
              <li>
                <strong>To comply with our legal obligations.</strong> We may
                process your information to comply with our legal obligations,
                respond to legal requests, and exercise, establish, or defend
                our legal rights.
              </li>
            </ul>
          </p>
          <h2 id="when_and_with_whom_do_we_share">
            3. When and with whom do we share your personal information?
          </h2>
          <p>
            <strong>In Short:</strong>
            <i>
              {" "}
              We may share information in specific situations described in this
              section and/or with the following third parties.
            </i>
            <p>
              We may need to share your personal information in the following
              situations:
            </p>
            <ul>
              <li>
                <strong>Business Transfers.</strong> We may share or transfer
                your information in connection with, or during negotiations of,
                any merger, sale of company assets, financing, or acquisition of
                all or a portion of our business to another company.
              </li>
              <li>
                <strong>When we use Google Maps Platform APIs.</strong> We may
                share your information with certain Google Maps Platform APIs
                (e.g., Google Maps API, Places API). We obtain and store on your
                device ("cache") your location. You may revoke your consent
                anytime by contacting us at the contact details provided at the
                end of this document.
              </li>
              <li>
                <strong>Other Users.</strong> When you share personal
                information (for example, by posting comments, contributions, or
                other content to the Services) or otherwise interact with public
                areas of the Services, such personal information may be viewed
                by all users and may be publicly made available outside the
                Services in perpetuity. If you interact with other users of our
                Services and register for our Services through a social network
                (such as Facebook), your contacts on the social network will see
                your name, profile photo, and descriptions of your activity.
                Similarly, other users will be able to view descriptions of your
                activity, communicate with you within our Services, and view
                your profile.
              </li>
            </ul>
          </p>
          <h2 id="do_we_use_cookies">
            4. Do we use cookies and other technologies?
          </h2>
          <p>
            <strong>In Short:</strong>
            <i>
              {" "}
              We may use cookies and other tracking technologies to collect and
              store your information.
            </i>
            <p>
              We may use cookies and similar tracking technologies (like web
              beacons and pixels) to access or store information. Specific
              information about how we use such technologies and how you can
              refuse certain cookies is set out in our Cookie Notice.
            </p>
          </p>
          <h2 id="how_do_we_handle_your_social_logins">
            5. How do we handle your social logins?
          </h2>
          <p>
            <strong>In Short:</strong>
            <i>
              {" "}
              If you choose to register or log in to our Services using a social
              media account, we may have access to certain information about
              you.
            </i>
            <p>
              Our Services offer you the ability to register and log in using
              your third-party social media account details (like your Facebook
              or Twitter logins). Where you choose to do this, we will receive
              certain profile information about you from your social media
              provider. The profile information we receive may vary depending on
              the social media provider concerned, but will often include your
              name, email address, friends list, and profile picture, as well as
              other information you choose to make public on such a social media
              platform.
            </p>
            <p>
              We will use the information we receive only for the purposes that
              are described in this privacy notice or that are otherwise made
              clear to you on the relevant Services. Please note that we do not
              control, and are not responsible for, other uses of your personal
              information by your third-party social media provider. We
              recommend that you review their privacy notice to understand how
              they collect, use, and share your personal information, and how
              you can set your privacy preferences on their sites and apps.
            </p>
          </p>
          <h2 id="how_long_do_we_keep_your_information">
            6. How long do we keep your information?
          </h2>
          <p>
            <strong>In Short:</strong>
            <i>
              {" "}
              We keep your information for as long as necessary to fulfill the
              purposes outlined in this privacy notice unless otherwise required
              by law.
            </i>
            <p>
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting, or other legal requirements). No purpose in this
              notice will require us keeping your personal information for
              longer than the period of time in which users have an account with
              us.
            </p>
            <p>
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymize such
              information, or, if this is not possible (for example, because
              your personal information has been stored in backup archives),
              then we will securely store your personal information and isolate
              it from any further processing until deletion is possible.
            </p>
          </p>
          <h2 id="how_do_we_keep_your_information_safe">
            7. How do we keep your information safe?
          </h2>
          <p>
            <strong>In Short:</strong>
            <i>
              {" "}
              We aim to protect your personal information through a system of
              organizational and technical security measures.
            </i>
            <p>
              We have implemented appropriate and reasonable technical and
              organizational security measures designed to protect the security
              of any personal information we process. However, despite our
              safeguards and efforts to secure your information, no electronic
              transmission over the Internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorized
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.
              Although we will do our best to protect your personal information,
              transmission of personal information to and from our Services is
              at your own risk. You should only access the Services within a
              secure environment.
            </p>
          </p>
          <h2 id="do_we_collect_information_from_minors">
            8. Do we collect information from minors?
          </h2>
          <p>
            <strong>In Short:</strong>
            <i>
              {" "}
              We do not knowingly collect data from or market to children under
              18 years of age.
            </i>
            <p>
              We do not knowingly solicit data from or market to children under
              18 years of age. By using the Services, you represent that you are
              at least 18 or that you are the parent or guardian of such a minor
              and consent to such minor dependent's use of the Services. If we
              learn that personal information from users less than 18 years of
              age has been collected, we will deactivate the account and take
              reasonable measures to promptly delete such data from our records.
              If you become aware of any data we may have collected from
              children under age 18, please contact us at
              will@satellitefriends.com.
            </p>
          </p>
          <h2 id="what_are_your_privacy_rights">
            9. What are your privacy rights?
          </h2>
          <p>
            <strong>In Short:</strong>
            <i>
              {" "}
              You may review, change, or terminate your account at any time.
            </i>
            <p>
              <u>
                <strong>Withdrawing your consent:</strong>
              </u>{" "}
              If we are relying on your consent to process your personal
              information, which may be express and/or implied consent depending
              on the applicable law, you have the right to withdraw your consent
              at any time. You can withdraw your consent at any time by
              contacting us by using the contact details provided in the section{" "}
              <a href="#how_can_you_contact_us">How can you contact us about this notice?</a>{" "}below.
            </p>
            <p>
              However, please note that this will not affect the lawfulness of
              the processing before its withdrawal nor, when applicable law
              allows, will it affect the processing of your personal information
              conducted in reliance on lawful processing grounds other than
              consent.
            </p>
            <h3>Account Information</h3>
            <p>
              If you would at any time like to review or change the information
              in your account or terminate your account, you can:
            </p>
            <ul>
              <li>Contact us using the contact information provided.</li>
            </ul>
            <p>
              Upon your request to terminate your account, we will deactivate or
              delete your account and information from our active databases.
              However, we may retain some information in our files to prevent
              fraud, troubleshoot problems, assist with any investigations,
              enforce our legal terms and/or comply with applicable legal
              requirements.
            </p>
            <p>
              <ul>
                <strong>Cookies and similar technologies:</strong>
              </ul>{" "}
              Most Web browsers are set to accept cookies by default. If you
              prefer, you can usually choose to set your browser to remove
              cookies and to reject cookies. If you choose to remove cookies or
              reject cookies, this could affect certain features or services of
              our Services.
            </p>
            <p>
              {" "}
              If you have questions or comments about your privacy rights, you
              may email us at will@satellitefriends.com.
            </p>{" "}
          </p>
          <h2>10. Controls for do-not-track features</h2>
          <p>
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track ("DNT") feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. At
            this stage no uniform technology standard for recognizing and
            implementing DNT signals has been finalized. As such, we do not
            currently respond to DNT browser signals or any other mechanism that
            automatically communicates your choice not to be tracked online. If
            a standard for online tracking is adopted that we must follow in the
            future, we will inform you about that practice in a revised version
            of this privacy notice.
          </p>
          <h2 id="california">
            11. Do California residents have specific privacy rights?
          </h2>
          <p>
            <strong>In Short:</strong>
            <i>
              {" "}
              Yes, if you are a resident of California, you are granted specific
              rights regarding access to your personal information.
            </i>
            <p>
              California Civil Code Section 1798.83, also known as the "Shine
              The Light" law, permits our users who are California residents to
              request and obtain from us, once a year and free of charge,
              information about categories of personal information (if any) we
              disclosed to third parties for direct marketing purposes and the
              names and addresses of all third parties with which we shared
              personal information in the immediately preceding calendar year.
              If you are a California resident and would like to make such a
              request, please submit your request in writing to us using the
              contact information provided below.
            </p>
            <p>
              If you are under 18 years of age, reside in California, and have a
              registered account with Services, you have the right to request
              removal of unwanted data that you publicly post on the Services.
              To request removal of such data, please contact us using the
              contact information provided below and include the email address
              associated with your account and a statement that you reside in
              California. We will make sure the data is not publicly displayed
              on the Services, but please be aware that the data may not be
              completely or comprehensively removed from all our systems (e.g.,
              backups, etc.).
            </p>
          </p>
          <h3>CCPA Privacy Notice</h3>
          <p>The California Code of Regulations defines a "resident" as:</p>
          <ul>
            (1) every individual who is in the State of California for other
            than a temporary or transitory purpose
          </ul>
          <ul>
            (2) every individual who is domicited in the State of California who
            is outside the State of California for a temporary or transitory
            purpose
          </ul>
          <p>All other individuals are defined as "non-residents."</p>
          <p>
            If this definition of "resident" applies to you, we must adhere to
            certain rights and obligations regarding your personal information.
          </p>
          <p>
            <strong>
              What categories of personal information do we collect?
            </strong>
          </p>
          <p>
            We have collected the following categories of personal information
            in the past twelve (12) months:
          </p>
          <table style={styles.table}>
            <tr style={styles.table}>
              <th>Category</th>
              <th>Examples</th>
              <th>Collected</th>
            </tr>
            <tr>
              <td>A. Identifiers</td>
              <td>
                Contact details, such as real name, alias, postal address,
                telephone or mobile contact number, unique personal identifier,
                online identifier, Internet Protocol address, email address, and
                account name
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>
                B. Personal information categories listed in the California
                Customer Records statute
              </td>
              <td>
                Name, contact information, education, employment, employment
                history, and financial information
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>
                C. Protected classification characteristics under California or
                federal law
              </td>
              <td>Gender and date of birth</td>
              <td>YES</td>
            </tr>
            <tr>
              <td>D. Commercial information</td>
              <td>
                Transaction information, purchase history, financial details,
                and payment information
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>E. Biometric information</td>
              <td>Fingerprints and voiceprints</td>
              <td>NO</td>
            </tr>
            <tr>
              <td>F. Internet or other similar network activity</td>
              <td>
                Browsing history, search history, online behavior, interest
                data, and interactions with our and other websites,
                applications, systems, and advertisements
              </td>
              <td>NO</td>
            </tr>
            <tr>
              <td>G. Geolocation data</td>
              <td>Device location</td>
              <td>YES</td>
            </tr>
            <tr>
              <td>
                H. Audio, electronic, visual, thermal, olfactory, or similar
                information
              </td>
              <td>
                Images and audio, video or call recordings created in connection
                with our business activities
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>I. Professional or employment-related information</td>
              <td>
                Business contact details in order to provide you our Services at
                a business level or job title, work history, and professional
                qualifications if you apply for a job with us
              </td>
              <td>NO</td>
            </tr>
            <tr>
              <td>J. Education information</td>
              <td>Student records and directory information</td>
              <td>NO</td>
            </tr>
            <tr>
              <td>K. Inferences drawn from other personal information</td>
              <td>
                Inferences drawn from any of the collected personal information
                listed above to create a profile or summary about, for example,
                an individual's preferences and characteristics
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>L. Sensitive Personal Information</td>
              <td></td>
              <td>NO</td>
            </tr>
          </table>
          <p>
            We will use and retain the collected personal information as needed
            to provide the Services or for:
          </p>
          <ul>
            <li>Category A - As long as the user has an account with us</li>
            <li>Category B - As long as the user has an account with us</li>
            <li>Category C - As long as the user has an account with us</li>
            <li>Category D - As long as the user has an account with us</li>
            <li>Category G - As long as the user has an account with us</li>
            <li>Category K - As long as the user has an account with us</li>
          </ul>
          <p>
            We may also collect other personal information outside of these
            categories through instances where you interact with us in person,
            online, or by phone or mail in the context of:
          </p>
          <ul>
            <li>Receiving help through our customer support channels;</li>
            <li>Participation in customer surveys or contests; and</li>
            <li>
              Facilitation in the delivery of our Services and to respond to
              your inquiries.
            </li>
          </ul>
          <p>
            <strong>How do we use and share your personal information?</strong>
          </p>
          <p>
            More information about our data collection and sharing practices can
            be found in this privacy notice.
          </p>
          <p>
            You may contact us by email at will@satellitefriends.com, or by
            referring to the contact details at the bottom of this document.
          </p>
          <p>
            If you are using an authorized agent to exercise your right to opt
            out we may deny a request if the authorized agent does not submit
            proof that they have been validly authorized to act on your behalf.
          </p>
          <p>
            <strong>Will your information be shared with anyone else?</strong>
          </p>
          <p>
            We may disclose your personal information with our service providers
            pursuant to a written contract between us and each service provider.
            Each service provider is a for-profit entity that processes the
            information on our behalf, following the same strict privacy
            protection obligations mandated by the CCPA.
          </p>
          <p>
            We may use your personal information for our own business purposes,
            such as for undertaking internal research for technological
            development and demonstration. This is not considered to be
            "selling" of your personal information.
          </p>
          <p>
            We have not disclosed, sold, or shared any personal information to
            third parties for a business or commercial purpose in the preceding
            twelve (12) months. We will not sell or share personal information
            in the future belonging to website visitors, users, and other
            consumers.
          </p>
          <p>
            <strong>Your rights with respect to your personal data</strong>
          </p>
          <p>
            <u>Right to request deletion of the data — Request to delete</u>
          </p>
          <p>
            You can ask for the deletion of your personal information. If you
            ask us to delete your personal information, we will respect your
            request and delete your personal information, subject to certain
            exceptions provided by law, such as (but not limited to) the
            exercise by another consumer of his or her right to free speech, our
            compliance requirements resulting from a legal obligation, or any
            processing that may be required to protect against illegal
            activities.
          </p>
          <p>
            <u>Right to be informed — Request to know</u>
          </p>
          <p>Depending on the circumstances, you have a right to know:</p>
          <ul>
            <li>whether we collect and use your personal information;</li>
            <li>the categories of personal information that we collect;</li>
            <li>
              the purposes for which the collected personal information is used;
            </li>
            <li>
              whether we sell or share personal information to third parties;
              the categories of personal information that we sold, shared, or
              disclosed for a business purpose;
            </li>
            <li>
              the categories of third parties to whom the personal information
              was sold, shared, or disclosed for a business purpose;
            </li>
            <li>
              the business or commercial purpose for collecting, selling, or
              sharing personal information; and
            </li>
            <li>
              the specific pieces of personal infromation we collected about
              you.
            </li>
          </ul>
          <p>
            In accordance with applicable law, we are not obligated to provide
            or delete consumer information that is de-identified in response to
            a consumer request or to re-identify individual data to verify a
            consumer request.
          </p>
          <p>
            <u>
              Right to Non-Discrimination for the Exercise of a Consumer's
              Privacy Rights
            </u>
          </p>
          <p>
            We will not discriminate against you if you exercise your privacy
            rights.
          </p>
          <p>
            <u>
              Right to Limit Use and Disclosure of Sensitive Personal
              Information
            </u>
          </p>
          <p> We do not process consumer's sensitive personal information.</p>
          <p>
            <u>Verification process</u>
          </p>
          <p>
            Upon receiving your request, we will need to verify your identity to
            determine you are the same person about whom we have the information
            in our system. These verification efforts require us to ask you to
            provide information so that we can match it with information you
            have previously provided us. For instance, depending on the type of
            request you submit, we may ask you to provide certain information so
            that we can match the information you provide with the information
            we already have on file, or we may contact you through a
            communication method (e.g., phone or email) that you have previously
            provided to us. We may also use other verification methods as the
            circumstances dictate.
          </p>
          <p>
            We will only use personal information provided in your request to
            verify your identity or authority to make the request. To the extent
            possible, we will avoid requesting additional information from you
            for the purposes of verification. However, if we cannot verify your
            identity from the information already maintained by us, we may
            request that you provide additional information for the purposes of
            verifying your identity and for security or fraud-prevention
            purposes. We will delete such additionally provided information as
            soon as we finish verifying you.
          </p>
          <p>
            <u>Other privacy rights</u>
          </p>
          <ul>
            <li>
              You may object to the processing of your personal information.
            </li>
            <li>
              You may request correction of your personal data if it is
              incorrect or no longer relevant, or ask to restrict the processing
              of the information.
            </li>
            <li>
              You can designate an authorized agent to make a request under the
              CCPA on your behalf. We may deny a request from an authorized
              agent that does not submit proof that they have been validly
              authorized to act on your behalf in accordance with the CCPA.
            </li>
            <li>
              You may request to opt out from future selling or sharing of your
              personal information to third parties. Upon receiving an opt-out
              request, we will act upon the request as soon as feasibly
              possible, but no later than fifteen (15) days from the date of the
              request submission.
            </li>
          </ul>
          <p>
            To exercise these rights, you can contact us by email at
            will@satellitefriends.com, or by referring to the contact details at
            the bottom of this document. If you have a complaint about how we
            handle your data, we would like to hear from you.
          </p>
          <h2 id="virginia">
            12. Do Virginia residents have specific privacy rights?
          </h2>
          <p>
            <strong>In Short:</strong>
            <i>
              {" "}
              Yes, if you are a resident of Virginia, you may be granted
              specific rights regarding access to and use of your personal
              information.
            </i>
          </p>
          <h3>Virginia CDPA Privacy Notice</h3>
          <p>Under the Virginia Consumer Data Protection Act (CDPA):</p>
          <p>
            "Consumer" means a natural person who is a resident of the
            Commonwealth acting only in an individual or household context. It
            does not include a natural person acting in a commercial or
            employment context.
          </p>
          <p>
            "Personal data" means any information that is linked or reasonably
            linkable to an identified or identifiable natural person. "Personal
            data" does not include de- identified data or publicly available
            information.
          </p>
          <p>
            "Sale of personal data" means the exchange of personal data for
            monetary consideration.
          </p>
          <p>
            If this definition "consumer" applies to you, we must adhere to
            certain rights and obligations regarding your personal data.
          </p>
          <p>
            The information we collect, use, and disclose about you will vary
            depending on how you interact with us and our Services.
          </p>
          <p>
            <u>Your rights with respect to your personal data</u>
          </p>
          <ul>
            <li>
              Right to be informed whether or not we are processing your
              personal data
            </li>
            <li>Right to access your personal data</li>
            <li>Right to correct inaccuracies in your personal data</li>
            <li>Right to request deletion of your personal data</li>
            <li>
              Right to obtain a copy of the personal data you previously shared
              with us
            </li>
            <li>
              Right to opt out of the processing of your personal data if it is
              used for targeted advertising, the sale of personal data, or
              profiling in furtherance of decisions that produce legal or
              similarly significant effects ("profiling")
            </li>
          </ul>
          <p>
            We have not sold any personal data to third parties for business or
            commercial purposes. We will not sell personal data in the future
            belonging to website visitors, users, and other consumers.
          </p>
          <p>
            <u>Exercise your rights provided under the Virginia CDPA</u>
          </p>
          <p>
            More information about our data collection and sharing practices can
            be found in this privacy notice.
          </p>
          <p>
            You may contact us by email at will@satellitefriends.com, by
            submitting a data subject access request, or by referring to the
            contact details at the bottom of this document.
          </p>
          <p>
            If you are using an authorized agent to exercise your rights, we may
            deny a request if the authorized agent does not submit proof that
            they have been validly authorized to act on your behalf.
          </p>
          <p>
            <u>Verification process</u>
          </p>
          <p>
            We may request that you provide additional information reasonably
            necessary to verify you and your consumer's request. If you submit
            the request through an authorized agent, we may need to collect
            additional information to verify your identity before processing
            your request.
          </p>
          <p>
            Upon receiving your request, we will respond without undue delay,
            but in all cases, within forty-five (45) days of receipt. The
            response period may be extended once by forty-five (45) additional
            days when reasonably necessary. We will inform you of any such
            extension within the initial 45-day response period, together with
            the reason for the extension.
          </p>
          <p>
            <u>Right to appeal</u>
          </p>
          <p>
            If we decline to take action regarding your request, we will inform
            you of our decision and reasoning behind it. If you wish to appeal
            our decision, please email us at will@satellitefriends.com. Within
            sixty (60) days of receipt of an appeal, we will inform you in
            writing of any action taken or not taken in response to the appeal,
            including a written explanation of the reasons for the decisions. If
            your appeal if denied, you may contact the Attorney General to
            submit a complaint.
          </p>
          <h2>13. Do we make updates to this notice?</h2>
          <p>
            <strong>In Short:</strong>
            <i>
              {" "}
              Yes, we will update this notice as necessary to stay compliant
              with relevant laws.
            </i>
          </p>
          <p>
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated "Revised" date and the
            updated version will be effective as soon as it is accessible. If we
            make material changes to this privacy notice, we may notify you
            either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy notice frequently to be informed of how we are protecting
            your information.
          </p>
          <h2 id="how_can_you_contact_us">14. How can you contact us about the notice?</h2>
          <p>
            If you have questions or comments about this notice, you may email
            us at will@satellitefriends.com or contact us by post at:
          </p>
          <p>
            Satellite Friends Foundation
            <br />
            8605 Santa Monica Boulevard
            <br />
            West Hollywood, CA 90069
            <br />
            United States
          </p>
          <h2>
            15. How can you review, update, or delete the data we collect from
            you?
          </h2>
          <p>
            Based on the applicable laws of your country, you may have the right
            to request access to the personal information we collect from you,
            change that information, or delete it. To request to review, update,
            or delete your personal information, please fill out and submit a
            data subject access request.
          </p>
        </div>
      </Content>
    </div>
  );
};

export default Privacy;

const styles = {
  table: {
    border: "1px solid black",
  },
  background: {
    backgroundColor: "lightgrey",
    height: "100%",
  },
  titleBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
  },
  founderText: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
};
