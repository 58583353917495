import React, { useState } from "react";
import { Button, Input, Layout, Typography } from "antd";
import Header from "../../components/Header";
import { login } from "../../actions/userActions";
import { useSatelliteEnterpriseContext } from "../../context/SatelliteContext";
import { useHistory } from "react-router-dom";
import {
  BORDER_RADIUS,
  isDesktop,
  SPACING,
} from "../../configs/theme";
//@ts-ignore
import img from "../../assets/beach.jpeg";

const { Content } = Layout;
const { Title } = Typography;

export const Login = () => {
  const history = useHistory();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const {
    state: { db },
    dispatch,
  } = useSatelliteEnterpriseContext();
  const [email, onChangeEmail] = useState<string>("");
  const [password, onChangePassword] = useState<string>("");

  const handleLoginClick = async () => {
    setIsLoggingIn(true);
    const loginPayload = await login(db, email, password);
    if (loginPayload.isInvalid) {
      setIsLoggingIn(false);
    } else if (!loginPayload.isVerified) {
      setIsLoggingIn(false);
    } else {
      setIsLoggingIn(false);
      dispatch(loginPayload);
      history.push("/events");
    }
  };

  if (isDesktop) {
    return (
      //@ts-ignore
      <div style={styles.container}>
        <Header />
        <div
          style={{
            ...styles.background,
            ...{ backgroundImage: `url(${img})` },
          }}
        >
          <Content
            style={{
              padding: SPACING.LG,
              paddingTop: SPACING.MD,
              borderRadius: BORDER_RADIUS.ROUNDED,
              backgroundColor: "inherit",
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Title
              level={1}
              style={{ marginBottom: SPACING.MD, color: "white" }}
            >
              Login
            </Title>
            <Input
              size="large"
              style={{ marginBottom: SPACING.MD, width: "50%" }}
              value={email}
              onChange={(e) => onChangeEmail(e.target.value)}
              placeholder="Enter your email here..."
            />
            <Input.Password
              size="large"
              style={{ marginBottom: SPACING.MD, width: "50%" }}
              value={password}
              visibilityToggle={{
                visible: passwordVisible,
                onVisibleChange: setPasswordVisible,
              }}
              onChange={(e) => onChangePassword(e.target.value)}
              placeholder="Enter your password here..."
            />
            <Button
              type="primary"
              loading={isLoggingIn}
              shape="round"
              size="large"
              style={{ width: "25%" }}
              onClick={handleLoginClick}
            >
              Login
            </Button>
          </Content>
        </div>
      </div>
    );
  }
  return (
    //@ts-ignore
    <div style={styles.container}>
      <Header />
      <div
        style={{ ...styles.background, ...{ backgroundImage: `url(${img})` } }}
      >
        <Content
          style={{
            padding: SPACING.LG,
            paddingTop: SPACING.MD,
            borderRadius: BORDER_RADIUS.ROUNDED,
            backgroundColor: "inherit",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Title level={1} style={{ marginBottom: SPACING.MD, color: "white" }}>
            Login
          </Title>
          <Input
            size="large"
            style={{ marginBottom: SPACING.MD }}
            value={email}
            onChange={(e) => onChangeEmail(e.target.value)}
            placeholder="Enter your email here..."
          />
          <Input.Password
            size="large"
            style={{ marginBottom: SPACING.MD }}
            value={password}
            visibilityToggle={{
              visible: passwordVisible,
              onVisibleChange: setPasswordVisible,
            }}
            onChange={(e) => onChangePassword(e.target.value)}
            placeholder="Enter your password here..."
          />
          <Button
            type="primary"
            loading={isLoggingIn}
            shape="round"
            size="large"
            style={{ width: "50%" }}
            onClick={handleLoginClick}
          >
            Login
          </Button>
        </Content>
      </div>
    </div>
  );
};

const styles = {
  container: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  background: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    flex: 1,
  },
};

export default Login;
