import { DatePicker, Typography } from "antd";
import React from "react";
import { SPACING } from "../../../configs/theme";
import { updateActiveEvent } from "../../../actions/eventActions";
import { useSatelliteEnterpriseContext } from "../../../context/SatelliteContext";
import { Timestamp } from "firebase/firestore";

export const Range = () => {
  const { state: { activeEvent }, dispatch } = useSatelliteEnterpriseContext();
  const onChange = (e) => {
    const startTime = Timestamp.fromDate(e[0].toDate());
    const endTime = Timestamp.fromDate(e[1].toDate());
    updateActiveEvent({...activeEvent, startTime, endTime}, dispatch);
  };

  return (
    <div style={{marginBottom: SPACING.SM}} >
      <Typography.Text style={{ fontWeight: "bold", marginBottom: SPACING.SM  }}>
        Start and end time:
      </Typography.Text>
      <DatePicker.RangePicker
        style={{width: "100%"}}
        showTime={{ format: "HH:mm" }}
        format="YYYY-MM-DD HH:mm"
        onChange={onChange}
        allowEmpty={[false, false]}
      />
    </div>
  );
};
