import React from "react";
import { Layout, Typography } from "antd";
import Header from "../../components/Header";
import { useSatelliteEnterpriseContext } from "../../context/SatelliteContext";
import { BORDER_RADIUS, FONT_SIZE, SPACING, isDesktop } from "../../configs/theme";

const { Content } = Layout;
const { Title, Text } = Typography;

export const Support = () => {
  const {
    state: { user },
  } = useSatelliteEnterpriseContext();
  return (
    <div style={styles.background}>
      <Header isLoggedIn={!!user} />
      <Content style={{...styles.content, margin: isDesktop ? SPACING.MD : SPACING.XS}}>
        <Title level={1} style={{ color: "white", marginBottom: SPACING.MD }}>
          Support
        </Title>
        <Text
          style={{ color: "white", fontSize: isDesktop ? FONT_SIZE.LG : FONT_SIZE.SM, marginTop: SPACING.SM }}
        >
          Have any questions? Shoot an email to will@satellitefriends.com 😊
        </Text>
      </Content>
    </div>
  );
};

export default Support;

const styles = {
  background: {
    backgroundColor: "#2196f3",
    height: "100vh",
    backgroundImage: "linear-gradient(160deg, #2196f3 0%, #90caf9 100%)",
  },
  titleBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: BORDER_RADIUS.ROUNDED,
  },
  founderText: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
};
