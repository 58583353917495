import React from 'react';
import { ColumnsType } from "antd/es/table";
import { Typography } from "antd";
import { City } from '../../../types/City';

const { Text } = Typography;

export const columns: ColumnsType<City> = [
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: (a, b) => a.name.length - b.name.length,
    sortDirections: ['descend'],
  },
];