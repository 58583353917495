import React from "react";
import { Layout, Typography, Image, Spin } from "antd";
import Header from "../../components/Header";
import { useSatelliteEnterpriseContext } from "../../context/SatelliteContext";
import { AVATAR_SIZE, BORDER_RADIUS, FONT_SIZE, SPACING } from "../../configs/theme";
import img from "../../assets/beach.jpeg";

const { Content } = Layout;
const { Title, Text } = Typography;

export const Account = () => {
  const {
    state: { user },
  } = useSatelliteEnterpriseContext();

  if (!user) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "100vh",
          justifyContent: "center",
        }}
      >
        <Spin tip="Loading" size="large" />
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <Header />
      <div
        style={{ ...styles.background, ...{ backgroundImage: `url(${img})` } }}
      >
        <Content style={styles.content}>
          <div style={styles.titleBox}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Image
                width={AVATAR_SIZE.MD}
                style={{ borderRadius: BORDER_RADIUS.CIRCLE }}
                src={user?.imageURL}
              />
              <Title style={{ marginLeft: SPACING.MD }} level={2}>
                {user?.firstName} {user?.lastName}
              </Title>
            </div>
          </div>
          <div style={{ padding: SPACING.SM }}>
            <Text style={{ fontSize: FONT_SIZE.LG }}>
              To change your user's{" "}
              <Text style={{ fontSize: FONT_SIZE.LG, fontWeight: "bold" }}>
                Profile
              </Text>{" "}
              please do so on the{" "}
              <Text style={{ fontSize: FONT_SIZE.LG, fontWeight: "bold" }}>
                mobile app
              </Text>
              .
            </Text>
          </div>
        </Content>
      </div>
    </div>
  );
};
export default Account;

const styles = {
  background: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    flex: 1,
  },
  titleBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  content: {
    margin: SPACING.MD,
    borderRadius: BORDER_RADIUS.ROUNDED,
    padding: SPACING.MD,
    backgroundColor: "white",
  },
  container: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },
};
