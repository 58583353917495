import { Input, Typography } from "antd";
import React from "react";
import {
  UPDATE_ACTIVE_VENUE,
  useSatelliteEnterpriseContext,
} from "../../../../context/SatelliteContext";
import { SPACING } from "../../../../configs/theme";

const { Text } = Typography;
const { TextArea } = Input;

export const DescriptionSection = () => {
  const {
    state: { activeVenue },
    dispatch,
  } = useSatelliteEnterpriseContext();

  const updateActiveVenueValue = (key: string, value: any) => {
    dispatch({
      type: UPDATE_ACTIVE_VENUE,
      activeVenue: { ...activeVenue, [key]: value },
    });
  };

  return (
    <div>
      <Text style={{fontWeight: "bold"}}>Description:</Text>
      <TextArea
        onChange={(e) => updateActiveVenueValue("description", e.target.value)}
        value={activeVenue?.description}
        rows={4}
        style={{ marginBottom: SPACING.SM }}
        placeholder="Describe your venue here..."
      />
    </div>
  );
};

export default DescriptionSection;
