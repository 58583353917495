import { Input, Select, Typography } from "antd";
import React from "react";
import {
  UPDATE_ACTIVE_VENUE,
  useSatelliteEnterpriseContext,
} from "../../../../context/SatelliteContext";
import { SPACING } from "../../../../configs/theme";

const { Text } = Typography;

const pricePointOptions = [
  {
    value: 0,
    label: "Free",
  },
  {
    value: 1,
    label: "$",
  },
  {
    value: 2,
    label: "$$",
  },
  {
    value: 3,
    label: "$$$",
  },
  {
    value: 4,
    label: "$$$$",
  },
]

export const DetailsSection = () => {
  const {
    state: { activeVenue },
    dispatch,
  } = useSatelliteEnterpriseContext();

  const updateActiveVenueValue = (key: string, value: any) => {
    dispatch({
      type: UPDATE_ACTIVE_VENUE,
      activeVenue: { ...activeVenue, [key]: value },
    });
  };

  return (
    <div>
      <Text style={{fontWeight: "bold"}}>Website URL:</Text>
      <Input
        onChange={(e) => updateActiveVenueValue("webURL", e.target.value)}
        value={activeVenue?.webURL}
        style={{ marginBottom: SPACING.SM }}
        placeholder="Add the website here..."
      />
      <Text style={{fontWeight: "bold"}}>What is the price point?:</Text>
      <Select
        allowClear
        style={{ width: '100%', marginBottom: SPACING.SM }}
        placeholder="Please select a price point..."
        value={pricePointOptions?.find(n => n.value == activeVenue?.pricePoint)}
        onChange={(e)=>updateActiveVenueValue('pricePoint', e)}
        options={pricePointOptions}
      />
    </div>
  );
};

export default DetailsSection;
