import { Input, Typography } from "antd";
import React from "react";
import {
  UPDATE_ACTIVE_VENUE,
  useSatelliteEnterpriseContext,
} from "../../../../context/SatelliteContext";
import { SPACING } from "../../../../configs/theme";

const { Text } = Typography;

export const NameSection = () => {
  const {
    state: { activeVenue },
    dispatch,
  } = useSatelliteEnterpriseContext();

  const updateActiveVenueValue = (key: string, value: any) => {
    dispatch({
      type: UPDATE_ACTIVE_VENUE,
      activeVenue: { ...activeVenue, [key]: value },
    });
  };

  return (
    <div>
      <Text style={{ fontWeight: "bold" }}>Venue name:</Text>
      <Input
        value={activeVenue?.name}
        onChange={(e) => updateActiveVenueValue("name", e.target.value)}
        style={{ marginBottom: SPACING.SM }}
        placeholder="The Getty Center, Griffith Park, etc."
      />
    </div>
  );
};

export default NameSection;
