import { Select, Typography } from "antd";
import React from "react";
import {
  UPDATE_ACTIVE_VENUE,
  useSatelliteEnterpriseContext,
} from "../../../../context/SatelliteContext";
import { Neighborhood } from "../../../../types/Neighborhood";
import AutoComplete from "./AutoComplete";
import { SPACING } from "../../../../configs/theme";

const { Text } = Typography;

export const LocationSection = () => {
  const {
    state: { activeVenue, activities, cities, neighborhoods },
    dispatch,
  } = useSatelliteEnterpriseContext();
  
  const updateActiveVenueValue = (key: string, value: any) => {
    dispatch({
      type: UPDATE_ACTIVE_VENUE,
      activeVenue: { ...activeVenue, [key]: value },
    });
  };

  const handleUpdateCity = (e: string) => {
    dispatch({
      type: UPDATE_ACTIVE_VENUE,
      activeVenue: { ...activeVenue, cityID: e, neighborhoodID: null, activities: []},
    });
  };

  const formatCitiesForSelect = cities.map((c) => {
    return {
      ...c,
      key: c.cityID,
      label: c.name,
      value: c.cityID,
    };
  });

  const formatNeighborhoodsForCity = neighborhoods.filter(
    (n: Neighborhood) => n.cityID === activeVenue?.cityID
  );

  const formattedActivitiesForSelect = activities
    .filter((a) => a.cityID === activeVenue?.cityID)
    ?.map((a) => {
      return {
        ...a,
        key: a.id,
        label: `${a.name} ${a.emoji}`,
        value: a.activityID,
      };
    });

  return (
    <div>
      <AutoComplete />
      <Text style={{ fontWeight: "bold" }}>City:</Text>
      <Select
        allowClear
        style={{ width: "100%", marginBottom: SPACING.SM }}
        placeholder="Please select a city..."
        value={cities.find((c) => c.cityID == activeVenue?.cityID)?.name || ""}
        onChange={handleUpdateCity}
        options={formatCitiesForSelect}
      />
      <Text style={{ fontWeight: "bold" }}>Neighborhood:</Text>
      <Select
        disabled={!activeVenue?.cityID}
        allowClear
        style={{ width: "100%", marginBottom: SPACING.SM }}
        placeholder="Please select a neighborhood..."
        value={formatNeighborhoodsForCity?.find(
          (n) => n.neighborhoodID == activeVenue?.neighborhoodID
        )}
        onChange={(e) => updateActiveVenueValue("neighborhoodID", e)}
        options={formatNeighborhoodsForCity}
      />
      <Text style={{ fontWeight: "bold" }}>Activities available:</Text>
      <Select
        disabled={!activeVenue?.cityID}
        mode="multiple"
        allowClear
        style={{ width: "100%", marginBottom: SPACING.SM }}
        placeholder="Please select at least one activity..."
        value={
          activeVenue?.activities?.map((activityID) => {
            return formattedActivitiesForSelect?.find(
              (a) => a.activityID === activityID
            );
          }) || []
        }
        onChange={(e) => updateActiveVenueValue("activities", e)}
        options={formattedActivitiesForSelect}
      />
    </div>
  );
};

export default LocationSection;
