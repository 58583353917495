import { Firestore, collectionGroup, getDocs, query } from "firebase/firestore";
import { Neighborhood } from "../types/Neighborhood";

export const readNeighborhoods = async (
  db: Firestore
): Promise<Neighborhood[]> => {
  const response = query(
    collectionGroup(db, `neighborhoods`),
  );
  const documentSnapshots = await getDocs(response);
  return documentSnapshots.docs.map((n) => {
    const neighborhood = n.data() as Neighborhood;
    return {
      ...neighborhood,
      id:  `${neighborhood.cityID}_${neighborhood.neighborhoodID}`,
      label: neighborhood.name,
      value: neighborhood.neighborhoodID,
      key: neighborhood.neighborhoodID,
    };
  });
};
