import React, { useState } from "react";
import { Button, Layout, Spin, Typography } from "antd";
import {
  UPDATE_ACTIVE_CITY,
  useSatelliteEnterpriseContext,
} from "../../context/SatelliteContext";
import Header from "../../components/Header";
import { CitiesTable } from "./Table";
import { City } from "../../types/City";
//@ts-ignore
import img from "../../assets/beach.jpeg";
import { BORDER_RADIUS, SPACING } from "../../configs/theme";
import CityModal from "./Modal";

const { Content } = Layout;
const { Title } = Typography;

export const Cities = () => {
  const {
    state: { user, cities },
    dispatch,
  } = useSatelliteEnterpriseContext();

  const [isCityModalOpen, setIsCityModalOpen] = useState<boolean>(false);

  const onCreateCityButtonClick = () => {
    dispatch({ type: UPDATE_ACTIVE_CITY, activeCity: undefined });
    setIsCityModalOpen(true);
  };

  if (!user) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "100vh",
          justifyContent: "center",
        }}
      >
        <Spin tip="Loading" size="large" />
      </div>
    );
  }

  const onCityClick = (record: City) => {
    dispatch({ type: UPDATE_ACTIVE_CITY, activeCity: record });
    setIsCityModalOpen(true);
  };

  return (
    //@ts-ignore
    <div style={styles.container}>
      <Header />
      <div
        style={{ ...styles.background, ...{ backgroundImage: `url(${img})` } }}
      >
        <Content style={styles.content}>
          <div style={styles.titleBox}>
            <Title level={2}>Cities</Title>
          <Button
            type="primary"
            shape="round"
            size="large"
            onClick={onCreateCityButtonClick}
          >
            Create a city
          </Button>{" "}
          </div>

        <CitiesTable cities={cities} onCityClick={onCityClick} />
        <CityModal
          isOpen={isCityModalOpen}
          onCancel={() => setIsCityModalOpen(false)}
        />
        </Content>
      </div>
    </div>
  );
};
export default Cities;

const styles = {
  container: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  background: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    flex: 1,
  },
  content: {
    margin: SPACING.MD,
    borderRadius: BORDER_RADIUS.ROUNDED,
    padding: SPACING.MD,
    backgroundColor: "white",
  },
  titleBox: {
    display: "flex",
    alignItems: "center",
    marginBottom: SPACING.MD,
    justifyContent: "space-between",
  },
};
