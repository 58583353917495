export const SPACING = {
  XS: "0.5rem",
  SM: "1rem",
  MD: "2rem",
  LG: "3rem",
  XL: "4rem",
  XXXL: "8rem",
}

export const FONT_SIZE = {
  XS: 10,
  SM: 14,
  MD: 18,
  LG: 24,
}

export const BORDER_RADIUS = {
  ROUNDED: 40,
  CIRCLE: 100
}

export const AVATAR_SIZE = {
  SM: 50,
  MD: 100,
  LG: 200,
}

export const isDesktop = window.innerWidth > 600;
