import React from "react";
import { Input, Select, Typography } from "antd";
import { useSatelliteEnterpriseContext } from "../../../context/SatelliteContext";
import { SPACING } from "../../../configs/theme";
import {
  updateActiveEvent,
  updateActiveEventValue,
} from "../../../actions/eventActions";

export const Basics = () => {
  const {
    state: { activeEvent, venues, activities },
    dispatch,
  } = useSatelliteEnterpriseContext();
  const filteredActivities = activeEvent?.cityID ? activities.filter(a => activeEvent.cityID === a.cityID) : activities;
  return (
    <div>
      <Typography.Text style={{ fontWeight: "bold", marginBottom: SPACING.SM }}>
        Event name:
      </Typography.Text>
      <Input
        value={activeEvent?.name}
        onChange={(e) =>
          updateActiveEventValue(activeEvent, dispatch, "name", e.target.value)
        }
        style={{ marginBottom: SPACING.SM }}
        placeholder="Comedy night, Taylor Swift concert, etc.."
      />
      <Typography.Text style={{ fontWeight: "bold", marginBottom: SPACING.SM }}>
        Venue:
      </Typography.Text>
      <Select
        allowClear
        style={{ width: "100%", marginBottom: SPACING.SM }}
        placeholder="Please select a venue..."
        value={activeEvent?.venue?.venueID}
        onChange={(e) => {
          const venue = venues.find((v) => v?.venueID === e);
          updateActiveEvent(
            { ...activeEvent, venue, cityID: venue.cityID, activity: null },
            dispatch
          );
        }}
        options={venues?.map((v) => {
          return {
            ...v,
            key: v?.venueID,
            label: `${v.name}`,
            value: v?.venueID,
          };
        })}
      />
      <Typography.Text style={{ fontWeight: "bold", marginBottom: SPACING.SM }}>
        Activity:
      </Typography.Text>
      <Select
        allowClear
        style={{ width: "100%", marginBottom: SPACING.SM }}
        placeholder="Please select an activity..."
        value={activeEvent?.activity}
        onChange={(a) => {
          const activity = filteredActivities.find((act) => act?.activityID === a)?.activityID;
          updateActiveEvent(
            { ...activeEvent, activity },
            dispatch
          );
        }}
        options={filteredActivities?.map((a) => {
          return {
            ...a,
            key: a?.activityID,
            label: `${a.name}`,
            value: a?.activityID,
          };
        })}
      />
    </div>
  );
};
