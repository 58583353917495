import React from "react";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import { getAuth } from "firebase/auth";
import {
  LOGOUT,
  useSatelliteEnterpriseContext,
} from "../../context/SatelliteContext";
import { Product } from "../../types/Product";
// @ts-ignore
import img from "../../assets/satellite-logo-white.png";
import { SPACING } from "../../configs/theme";

const { Header: LayoutHeader } = Layout;

const items = {
  about: {
    label: <Link to="/about">About us</Link>,
    key: "about",
  },
  dashboard: {
    label: <Link to="/dashboard">Dashboard</Link>,
    key: "dashboard",
  },
  venues: {
    label: <Link to="/venues">Venues</Link>,
    key: "venues",
  },
  cities: {
    label: <Link to="/cities">Cities</Link>,
    key: "cities",
  },
  scheduledEvents: {
    label: <Link to="/events">Events</Link>,
    key: "events",
  },
  account: {
    label: "My account",
    style: { marginLeft: "auto" },
    key: "my-account",
    children: [
      {
        type: "group",
        label: "Settings",
        children: [
          // {
          //   label: <Link to="/subscription">Subscription</Link>,
          //   key: "subscription:1",
          // },
          {
            label: <Link to="/account">Account details</Link>,
            key: "account-details:2",
          },
        ],
      },
      {
        label: "Log out",
        style: { color: "red" },
        key: "log-out:3",
      },
    ],
  },
  home: {
    label: <Link to="/">Home</Link>,
    key: "home",
  },
  login: {
    label: <Link to="/login">Login</Link>,
    key: "login",
    style: { marginLeft: "auto" },
  },
};

const getPermittedItems = (subscription: Product, isAdmin: boolean) => {
  if (!subscription) {
    return [items.home, items.about, items.login];
  } else if (isAdmin) {
    return [
      items.home,
      items.about,
      items.cities,
      items.scheduledEvents,
      items.venues,
      items.account,
    ]
  }
  switch (subscription.name) {
    case "Basic":
      return [
        items.home,
        items.about,
        items.scheduledEvents,
        items.venues,
        items.account,
      ];
    case "Premium":
      return [items.home, items.account];
    case "Test":
      return [
        items.home,
        items.about,
        items.cities,
        items.scheduledEvents,
        items.venues,
        items.account,
      ];
    case "Small business":
      return [items.venues, items.account];
    case "Enterprise":
      return [items.venues, items.account];
  }
};

export const Header = () => {
  const {
    dispatch,
    state: { user },
  } = useSatelliteEnterpriseContext();
  const auth = getAuth();

  const permittedItems = getPermittedItems(user?.subscription, user?.isAdmin);
  const handleClick = ({ key }) => {
    switch (key) {
      case "log-out:3":
        localStorage.removeItem("user");
        dispatch({ type: LOGOUT });
        auth.signOut();
        window.location.href = "/";
    }
  };
  return (
    <div>
      <LayoutHeader
        style={{ padding: 0, display: "flex", backgroundColor: "#42a5f5" }}
      >
        <img
          alt="logo"
          style={{ objectFit: "contain", padding: SPACING.SM }}
          src={img}
        />
        <Menu
          style={styles.menu}
          onClick={handleClick}
          mode="horizontal"
          defaultSelectedKeys={["1"]}
          items={permittedItems}
        />
      </LayoutHeader>
    </div>
  );
};

export default Header;

const styles = {
  menu: {
    flex: 1,
    backgroundColor: "#42a5f5",
    display: "flex",
    color: "white",
    fontWeight: "bold",
    justifyContent: "center",
    alignItems: "center",
  },
};
