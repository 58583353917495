import React from "react";
import { Layout, Typography } from "antd";
import Header from "../../components/Header";
import { useSatelliteEnterpriseContext } from "../../context/SatelliteContext";
import { AVATAR_SIZE, SPACING, isDesktop } from "../../configs/theme";
import img from "../../assets/sunset.jpeg";
import appStore from "../../assets/app-store.png";

const { Content } = Layout;

export const APP_STORE_LOGO_HEIGHT = 80;

export const Home = () => {
  const {
    state: { user },
  } = useSatelliteEnterpriseContext();

  if (isDesktop) {
    return (
      <div style={styles.container}>
        <Header />
        <div
          style={{
            ...styles.background,
            ...{ backgroundImage: `url(${img})` },
          }}
        >
          <Content style={styles.content}>
            <Typography.Paragraph>
              <h1 style={{ color: "white", textAlign: "center" }}>
                Volleyball games are just a{" "}
                <span style={{ color: "white", fontStyle: "italic" }}>
                  swipe
                </span>{" "}
                away.
              </h1>
            </Typography.Paragraph>
            <form
              action="https://apps.apple.com/us/app/satellite-find-your-community/id1624280486"
              method="get"
              target="_blank"
            >
              <button type="submit" style={styles.button}>
                <img height={AVATAR_SIZE.MD} src={appStore} />
              </button>
            </form>
          </Content>
        </div>
      </div>
    );
  }
  return (
    <div style={styles.container}>
      <div>
        <Header isLoggedIn={!!user} />
      </div>
      <div
        style={{ ...styles.background, ...{ backgroundImage: `url(${img})` } }}
      >
        <Content style={{ ...styles.content, ...{ paddingTop: 0 } }}>
          <Typography.Paragraph>
            <h3 style={{ color: "white", textAlign: "center" }}>
              Volleyball games are just a{" "}
              <span style={{ color: "white", fontStyle: "italic" }}>swipe</span>{" "}
              away.
            </h3>
          </Typography.Paragraph>
          <form
            action="https://apps.apple.com/us/app/satellite-find-your-community/id1624280486"
            method="get"
            target="_blank"
          >
            <button type="submit" style={styles.button}>
              <img height={APP_STORE_LOGO_HEIGHT} src={appStore} />
            </button>
          </form>
        </Content>
      </div>
    </div>
  );
};
export default Home;

const styles = {
  container: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: SPACING.MD,
    paddingRight: SPACING.LG,
    paddingLeft: SPACING.LG,
  },
  image: {
    marginTop: SPACING.LG,
  },
  button: {
    backgroundColor: "inherit",
    border: "none",
    cursor: "pointer",
    padding: 0,
  },
  background: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    flex: 1,
  },
};
