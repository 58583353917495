import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Home } from "../pages/Home";
import Login from "../pages/Login";
import About from "../pages/About";
import Venues from "../pages/Venues";
import Admin from "../pages/R&D/Admin";
import Account from "../pages/Account";
import Subscription from "../pages/Subscription";
import Privacy from "../pages/Privacy";
import Support from "../pages/Support";
import {
  FETCH_PRODUCTS,
  LOGIN,
  useSatelliteEnterpriseContext,
} from "../context/SatelliteContext";
import {
  getAuth,
  onAuthStateChanged,
} from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { fetchProducts } from "../api/productHelpers";
import { Product } from "../types/Product";
import Events from "../pages/Events";
import { PrivateUser } from "../types/User";
import Cities from "../pages/Cities";
import { fetchVenues } from "../actions/venueActions";
import { fetchActivities } from "../actions/activityActions";
import { fetchCities } from "../actions/cityActions";
import { fetchNeighborhoods } from "../actions/neighborhoodActions";

export const Routes = () => {
  const {
    state: { user, db, isLocalHost },
    dispatch,
  } = useSatelliteEnterpriseContext();

  // icebox (backend)
  // useEffect(() => {
  //   fetch("https://satellite-server-lv7pyhuaua-uc.a.run.app/time")
  //     .then((res) => res.json())
  //     .then((data) => {
  //     });
  // }, []);

  onAuthStateChanged(getAuth(), (u) => {

    if (u && user === null && (u.emailVerified || isLocalHost)) {
      const docRef = doc(db, "users", u.uid);
      getDoc(docRef).then(async (userSnap) => {

        const userData = userSnap.data();

        if (userData?.userID  === user?.userID) {
          return;
        }

        const subscriptionRef = query(
          collection(db, `users/${u.uid}/subscriptions`),
          where("status", "in", ["trialing", "active"])
        );

        fetchProducts(db).then((response) => {
          dispatch({ type: FETCH_PRODUCTS, products: response });
          getDocs(subscriptionRef).then((subscriptionData) => {
            let subscription: Product;
            if (!subscriptionData) {
              subscription = response.find((p) => p.name === "Basic");
            }
            const subscriptionDoc = subscriptionData.docs[0]?.data();
            const subscriptionProductID = subscriptionDoc?.product?.id?.replace(
              "/products/",
              ""
            );
            if (subscriptionProductID) {
              subscription = response.find(
                (p) => p.productID === subscriptionProductID
              );
            } else if (response.length < 1) {
              subscription = {
                name: "Test",
                price: 0,
                category: "individual",
                active: true,
                priceID: "abc",
                productID: "abcd",
                description:
                  "This is a test subscription with all features enabled temporarily.",
              };
            } else {
              subscription = response.find((p) => p.name === "Basic");
            }
            dispatch({
              type: LOGIN,
              isVerified: true,
              isInvalid: false,
              user: {
                ...userData,
                subscription,
              } as PrivateUser,
            });
          });
        });
      });
    } else {
      return <Redirect to={{ pathname: "/login" }} />;
    }
  });

  useEffect(() => {

    const loadVenues = async () => {
      const venuesPayload = await fetchVenues(db, user.userID);
      dispatch(venuesPayload);
    }

    const loadActivities = async () => {
      const activitiesPayload = await fetchActivities(db);
      dispatch(activitiesPayload);
    }

    const loadCities = async () => {
      const citiesPayload = await fetchCities(db);
      dispatch(citiesPayload);
    }

    const loadNeighborhoods = async () => {
      const neighborhoodsPayload = await fetchNeighborhoods(db);
      dispatch(neighborhoodsPayload);
    }

    if (user) {
      loadVenues();
      loadActivities();
      loadCities();
      loadNeighborhoods();
    }
  }, [user?.userID])

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path="/venues" exact component={Venues} />
        <Route path="/" exact component={Home} />
        <Route path="/subscription" exact component={Subscription} />
        <Route path="/account" exact component={Account} />
        <Route path="/about" exact component={About} />
        <Route path="/events" exact component={Events} />
        <Route path="/cities" exact component={Cities} />
        <Route path="/admin" exact component={Admin} />
        <Route path="/support" exact component={Support} />
        <Route path="/privacy" exact component={Privacy} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
