import { Modal, Typography, Button } from "antd";
import React from "react";
import { fetchVenues } from "../../../actions/venueActions";
import {
  useSatelliteEnterpriseContext,
} from "../../../context/SatelliteContext";
import {
  deactivateVenue,
  activateVenue,
  updateVenueInDB,
} from "../../../api/venueHelpers";
import { Venue } from "../../../types/Venue";
import ActivitiesSection from "./components/NameSection";
import LocationSection from "./components/LocationSection";
import UploadSection from "./components/UploadSection";
import DescriptionSection from "./components/DescriptionSection";
import { uuidv4 } from "@firebase/util";
import DetailsSection from "./components/DetailsSection";
import { SPACING } from "../../../configs/theme";

const { Title } = Typography;

interface VenueModalProps {
  isOpen: boolean;
  onCancel: () => void;
}

export const VenueModal = (props: VenueModalProps) => {
  const { isOpen, onCancel } = props;
  const {
    state: { activeVenue, db, user },
    dispatch,
  } = useSatelliteEnterpriseContext();

  const createVenue = () => {
    if (activeVenue && user) {
      const newVenue: Venue = {
        ...activeVenue,
        isActive: true,
        isPublic: true,
        venueID: uuidv4(),
        userID: user?.userID,
      };
      updateVenueInDB(db, newVenue).then(async () => {
        const venuesPayload = await fetchVenues(db, user.userID);
        dispatch(venuesPayload);
        onCancel();
      });
    } else {
      console.log("No venue found.");
    }
  };

  const updateVenue = () => {
    if (activeVenue && user) {
      updateVenueInDB(db, activeVenue).then(async () => {
        const venuesPayload = await fetchVenues(db, user.userID);
        dispatch(venuesPayload);
        onCancel();
      });
    } else {
      console.log("No venue found.");
    }
  };

  const onSaveChanges = () => {
    activeVenue?.venueID ? updateVenue() : createVenue();
  };

  const onToggleActivation = () => {
    if (!activeVenue || !user) {
      return;
    }
    if (activeVenue.isActive) {
      deactivateVenue(db, activeVenue).then(async () => {
        const venuesPayload = await fetchVenues(db, user.userID);
        dispatch(venuesPayload);
        onCancel();
      });
    } else {
      activateVenue(db, activeVenue).then(async () => {
        const venuesPayload = await fetchVenues(db, user.userID);
        dispatch(venuesPayload);
        onCancel();
      });
    }
  };

  return (
    <Modal
      title={
        activeVenue?.venueID ? (
          <Title style={{ marginTop: 0 }} level={3}>
            Edit venue
          </Title>
        ) : (
          <Title style={{ marginTop: 0 }} level={3}>
            Create a venue
          </Title>
        )
      }
      open={isOpen}
      width={800}
      cancelText={"Cancel"}
      okButtonProps={{type: "primary", shape: "round", size: "large", disabled: !activeVenue?.cityID}}
      cancelButtonProps={{type: "default", shape: "round", size: "large"}}
      onCancel={onCancel}
      onOk={onSaveChanges}
      okText={"Save changes"}
    >
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: SPACING.MD,
          }}
        >
          <div>
            <ActivitiesSection />
            <LocationSection />
            <DetailsSection />
          </div>
          <div style={{ marginLeft: SPACING.LG }}>
            <UploadSection />
            <DescriptionSection />
          </div>
        </div>
        {activeVenue?.venueID && user?.isAdmin && (
          <Button danger onClick={onToggleActivation}>
            {activeVenue?.isActive ? "Deactivate venue" : "Activate venue"}
          </Button>
        )}
      </>
    </Modal>
  );
};

export default VenueModal;
