import { Modal, Typography, Button } from "antd";
import React, { useState } from "react";
import {
  useSatelliteEnterpriseContext,
} from "../../../context/SatelliteContext";
import { SPACING } from "../../../configs/theme";
import { Basics } from "./Basics";
import { Range } from "./Range";
import { Attendance } from "./Attendance";
import { Cost } from "./Cost";
import { Description } from "./Description";
import Upload from "./Upload";
import { ScheduledEvent } from "../../../types/Event";
import uuid from "react-uuid";
import { updateEventInDB } from "../../../actions/eventActions";

const { Title } = Typography;

interface EventModalProps {
  isOpen: boolean;
  onCancel: () => void;
}

export const EventModal = (props: EventModalProps) => {
  const { isOpen, onCancel } = props;
  const {
    state: { activeEvent, user, db },
    dispatch,
  } = useSatelliteEnterpriseContext();

  const [isLoading, setIsLoading] = useState(false);

  const createEvent = () => {
    if (activeEvent && user && user.userID) {
      setIsLoading(true);
      const newEvent: ScheduledEvent = {
        ...activeEvent,
        rsvpList: [],
        eventID: uuid(),
        createdByUserID: user.userID,
      };
      updateEventInDB(db, newEvent, dispatch, true).then(() => {
        setIsLoading(false);
        onCancel();
      });
    }
  };

  const updateEvent = () => {
    if (activeEvent && user) {
      setIsLoading(true);
      updateEventInDB(db, activeEvent, dispatch, false).then(async () => {
        setIsLoading(false);
        onCancel();
      });
    } else {
      console.log("No venue found.");
    }
  };

  const onSaveChanges = () => {
    activeEvent?.eventID ? updateEvent() : createEvent();
  };

  return (
    <Modal
      title={
        activeEvent?.venueID ? (
          <Title style={{ marginTop: 0 }} level={3}>
            Edit event
          </Title>
        ) : (
          <Title style={{ marginTop: 0 }} level={3}>
            Create an event
          </Title>
        )
      }
      open={isOpen}
      width={800}
      cancelText={"Cancel"}
      okButtonProps={{type: "primary", shape: "round", size: "large", loading: isLoading}}
      cancelButtonProps={{type: "default", shape: "round", size: "large", loading: isLoading}}
      onCancel={onCancel}
      onOk={onSaveChanges}
      okText={"Save changes"}
    >
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: SPACING.MD,
          }}
        >
          <div style={{width: "45%"}}>
            <Basics />
            <Range />
            <Attendance />
          </div>
          <div style={{ width: "45%" }}>
            <Upload />
            <Description />
            <Cost />
          </div>
        </div>
      </>
    </Modal>
  );
};

export default EventModal;
