import { Firestore } from "firebase/firestore";
import { DispatchPayload, FETCH_CITIES } from "../context/SatelliteContext";
import { readCities } from "../api/cityHelpers";

export const fetchCities = async (
  db: Firestore,
): Promise<DispatchPayload> => {
  return await readCities(db)
    .then((cities) => {
      return { type: FETCH_CITIES, cities } as DispatchPayload;
    })
    .catch((_) => {
      return { type: FETCH_CITIES, cities: [] };
    });
};
