import React, { useState } from "react";
import { Button, Layout, Spin, Table, theme, Typography } from "antd";
import {
  UPDATE_ACTIVE_VENUE,
  useSatelliteEnterpriseContext,
} from "../../context/SatelliteContext";
import { Redirect } from "react-router-dom";
import Header from "../../components/Header";
import { columns } from "./tableColumns";
import VenueModal from "./VenueModal";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { BORDER_RADIUS, SPACING } from "../../configs/theme";
//@ts-ignore
import img from "../../assets/beach.jpeg";

const { Content } = Layout;
const { Title } = Typography;

export const Venues = () => {
  const {
    state: { user, venues, activities },
    dispatch,
  } = useSatelliteEnterpriseContext();
  const auth = getAuth();
  const { useToken } = theme;
  const { token } = useToken();
  onAuthStateChanged(auth, (firebaseUser) => {
    if (!firebaseUser) {
      return <Redirect to={{ pathname: "/login" }} />;
    }
  });
  const [isVenueModalOpen, setIsVenueModalOpen] = useState<boolean>(false);

  const onCreateVenueButtonClick = () => {
    dispatch({ type: UPDATE_ACTIVE_VENUE, activeVenue: undefined });
    setIsVenueModalOpen(true);
  };

  if (!user) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "100vh",
          justifyContent: "center",
        }}
      >
        <Spin tip="Loading" size="large" />
      </div>
    );
  }

  return (
    //@ts-ignore
    <div style={styles.container}>
      <Header />
      <div
        style={{ ...styles.background, ...{ backgroundImage: `url(${img})` } }}
      >
        <Content style={styles.content}>
          <div style={styles.titleBox}>
            <Title level={2}>
              Venues
            </Title>
            <Button
              type="primary"
              size="large"
              shape="round"
              onClick={onCreateVenueButtonClick}
            >
              Create a venue
            </Button>
          </div>
          <VenueModal
            isOpen={isVenueModalOpen}
            onCancel={() => setIsVenueModalOpen(false)}
          />
          <Table
            columns={columns}
            dataSource={venues.map((v) => {
              return {
                ...v,
                key: v.venueID,
                hydratedActivities: v.activities.map(
                  (activityID) =>
                    activities.find((a) => a.activityID === activityID) || {}
                ),
              };
            })}
            pagination={{ pageSize: 5 }}
            rowKey={(record) => record.venueID}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  dispatch({ type: UPDATE_ACTIVE_VENUE, activeVenue: record });
                  setIsVenueModalOpen(true);
                },
              };
            }}
          />
        </Content>
      </div>
    </div>
  );
};
export default Venues;

const styles = {
  container: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  background: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    flex: 1,
  },
  content: {
    margin: SPACING.MD,
    borderRadius: BORDER_RADIUS.ROUNDED,
    padding: SPACING.MD,
    backgroundColor: "white",
  },
  titleBox: {
    display: "flex",
    alignItems: "center",
    marginBottom: SPACING.MD,
    justifyContent: "space-between",
  },
};
