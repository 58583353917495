import { collection, Firestore, getDocs, query } from "firebase/firestore";
import { Product } from "../types/Product";

export const fetchProducts = async (db: Firestore): Promise<Product[]> => {
  const response = query(collection(db, `products`));
  return getDocs(response)
    .then((productSnapshots) => {
      const productPromises = productSnapshots.docs.map(async (snap) => {
        const priceRef = collection(db, `products/${snap.id}/prices`);
        const priceSnap = await getDocs(priceRef);
        const product = {
          ...snap.data(),
          prices: priceSnap.docs.map((d) => {
            return { ...d.data(), id: d.id };
          }),
        } as any;
        return {
          name: product.name,
          productID: snap.id,
          category: product.stripe_metadata_category,
          description: product.description,
          price: product.prices[0]?.unit_amount / 100,
          active: product.active,
          priceID: product.prices[0].id,
        };
      });
      return Promise.all(productPromises);
    })
    .catch((e) => {
      console.log(e);
      return [];
    });
};
