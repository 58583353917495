import React from "react";
import { Card, Button, List, Typography, Badge } from "antd";

interface PricingCardProps {
  title: string;
  price: number;
  isDisabled: boolean;
  description: string;
  features: string[];
  onSelect: () => void;
  isLoading?: boolean;
  isActive?: boolean;
}

const { Text } = Typography;

export const PricingCard: React.FC<PricingCardProps> = ({
  title,
  price,
  isDisabled,
  description,
  features,
  onSelect,
  isLoading,
  isActive,
}) => {
  const borderStyles = isActive
    ? {
        borderColor: "black",
        borderWidth: "3px",
      }
    : {};

  const renderCard = () => {
    return (
      <Card
        onClick={onSelect}
        hoverable
        title={title}
        style={{ textAlign: "center", ...borderStyles }}
      >
        <h1 style={{ marginTop: 0 }}>${price}/month</h1>
        <p>{description}</p>
        <List
          dataSource={features}
          style={{ marginBottom: 20 }}
          renderItem={(item) => (
            <List.Item>
              <Text italic>{item}</Text>
            </List.Item>
          )}
        />
        <Button
          size="large"
          type="primary"
          loading={isLoading || false}
          style={{ marginTop: "auto" }}
          disabled={isDisabled || isActive}
          onClick={onSelect}
        >
          {isDisabled
            ? "Coming soon"
            : isActive
            ? "Currently active"
            : title == "Basic"
            ? "Choose"
            : "Purchase"}
        </Button>
      </Card>
    );
  };

  if (isDisabled) {
    return (
      <div style={{ width: "45%" }}>
        <Badge.Ribbon text="Coming soon!">{renderCard()}</Badge.Ribbon>
      </div>
    );
  } else {
    return <div style={{ width: "45%" }}>{renderCard()}</div>;
  }
};

export default PricingCard;
