import { collectionGroup, doc, Firestore, getDocs, query, setDoc } from "firebase/firestore";
import { Activity } from "../types/Activity";

export const readActivities = async (
  db: Firestore,
): Promise<Activity[]> => {

  const response = query(
    collectionGroup(db, 'activities'),
  );

  const documentSnapshots = await getDocs(response);
  return documentSnapshots.docs.map((d) => {
    const { name, activityID, cityID, emoji } = d.data();
    return {
      id: `${cityID}_${activityID}`,
      name,
      cityID,
      activityID,
      emoji,
    } as Activity;
  });
};

export const addActivityToCity = async (db: Firestore, activity: Activity, cityID: string) => {
  try {
    await setDoc(doc(db, `cities/${cityID}/activities`, activity.activityID), {
      ...activity,
      cityID,
      userIDs: []
    });
  } catch (e) {
    console.log(e)
  }
}