import { Firestore } from "firebase/firestore";
import { DispatchPayload, FETCH_ACTIVITIES } from "../context/SatelliteContext";
import { readActivities } from "../api/activityHelpers";

export const fetchActivities = async (
  db: Firestore,
): Promise<DispatchPayload> => {
  return await readActivities(db)
    .then((activities) => {
      return {
        activities,
        type: FETCH_ACTIVITIES,
      } as DispatchPayload;
    })
    .catch((_) => {
      return { type: FETCH_ACTIVITIES, activities: [] };
    });
};
