import { doc, Firestore, getDoc } from "firebase/firestore";
import { browserLocalPersistence, getAuth, setPersistence, signInWithEmailAndPassword } from "firebase/auth";
import { DispatchPayload, LOGIN } from "../context/SatelliteContext";
import { PrivateUser } from "../types/User";

export const login = async (
  db: Firestore,
  email: string,
  password: string
): Promise<DispatchPayload> => {
  const auth = getAuth();
  return setPersistence(auth, browserLocalPersistence).then(async () => {
    const userCredentials = await signInWithEmailAndPassword(
      auth,
      email,
      password
    ).catch((error) => {
      console.log(error)
    });
    if (userCredentials) {
      // downloads the user from firestore
      const docRef = doc(db, "users", userCredentials.user.uid);
      const userSnap = await getDoc(docRef);
      if (userSnap.exists()) {
        return {
          type: LOGIN,
          isVerified: true,
          isInvalid: false,
          user: userSnap.data() as PrivateUser,
        };
      } else {
        console.log("Couldn't find user!");
        return { type: LOGIN, isVerified: false, isInvalid: true };
      }
    } else {
      return { type: LOGIN, isVerified: false, isInvalid: true };
    }
  })

};