import { Upload } from 'antd';
import { getDownloadURL, getStorage, ref, StorageReference, uploadBytes } from 'firebase/storage';
import React from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { UPDATE_ACTIVE_VENUE,useSatelliteEnterpriseContext } from '../../../../context/SatelliteContext';
import { AVATAR_SIZE, SPACING } from '../../../../configs/theme';

const { Dragger } = Upload;

const UploadSection: React.FC = () => {
  const { state: { activeVenue }, dispatch } = useSatelliteEnterpriseContext();

  const updateActiveVenueValue = (key: string, value: any) => {
    dispatch({type: UPDATE_ACTIVE_VENUE, activeVenue: {...activeVenue, [key]: value}})
  };

  const downloadImage = async (
    imageRef: StorageReference
  ): Promise<string | void> => {
    return getDownloadURL(imageRef).catch((error) => {
      console.log(error);
    });
  };

  const handleChange = async ({ fileList }) => {
    const storage = getStorage();
    try {
      await Promise.all(
        fileList.map(async (file) => {
          const fileRef = ref(storage, `venue_images/${activeVenue?.venueID ? activeVenue.venueID : file.name}.jpg`);
          try {
            if (!file.originFileObj) {
              return;
            }
            uploadBytes(fileRef, file.originFileObj)
              .then((snapshot) => {
                if (snapshot.ref) {
                  downloadImage(snapshot.ref).then((response) => {
                    if (response) {
                      updateActiveVenueValue('imageURL', response);
                    } else {
                      console.log("Unable to download image!");
                    }
                  });
                }
              })
          } catch (e) {
            console.log(e);
          }
        })
      );
    } catch (err) {
      console.log(err);
    }
  }

  const beforeUpload = file => {
    if (!["image/jpeg", "image/png"].includes(file.type)) {
      console.log(`${file.name} is not a valid image type`, 2);
      return false;
    }
    return false;
  };

  return (
    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: SPACING.SM}}>
      <img
        style={{marginBottom: SPACING.SM, width: AVATAR_SIZE.LG}}
        src={activeVenue?.imageURL}
      />
      <Dragger 
        style={{width: "100%"}}
        listType="picture-card"
        beforeUpload={beforeUpload}
        onChange={handleChange}
        multiple={true}
        >
        <div className="uploadIcon">
          <UploadOutlined />
        </div>
        <div className="uploadText">
          <p>Drag and drop here</p>
        </div>
      </Dragger>
    </div>
  );
};

export default UploadSection;