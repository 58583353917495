import { collection, doc, Firestore, getDocs, query, setDoc, where } from "firebase/firestore";
import { Venue } from "../types/Venue";
import { getStorage, ref, uploadBytes } from "firebase/storage";

export const readVenues = async (db: Firestore, userID: string) => {
  const venuesRef = collection(db, "venues");
  const q = query(venuesRef, where("userID", "==", userID), where("isActive", "==", true));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(d => d.data());
};

export const updateVenueInDB = async (db: Firestore, venue: Venue) => {
  try {
    await setDoc(doc(db, "venues", venue.venueID), {
      ...venue,
      hydratedActivities: [],
    });
  } catch (e) {
    console.log(e);
  }
};

export const uploadImageForVenue = async (uri: RequestInfo, uniqueKey: string) => {
  const storage = getStorage();
  const response = await fetch(uri);
  const blob = await response.blob();
  var imageRef = ref(storage, `venue_images/${uniqueKey}.jpg`);
  return uploadBytes(imageRef, blob)
    .then((snapshot) => {
      return snapshot.ref;
    })
    .catch((error) => {
      console.log(error)
    });
};

export const deactivateVenue = async (db: Firestore, venue: Venue) => {
  try {
    await setDoc(doc(db, "venues", venue.venueID), {
      ...venue,
      isActive: false,
    });
  } catch (e) {
    console.log(e);
  }
};

export const activateVenue = async (db: Firestore, venue: Venue) => {
  try {
    await setDoc(doc(db, "venues", venue.venueID), {
      ...venue,
      isActive: true,
    });
  } catch (e) {
    console.log(e);
  }
};
