import React from "react";
import { Layout, Typography } from "antd";
import Header from "../../components/Header";
import { FONT_SIZE, SPACING, isDesktop } from "../../configs/theme";
import img from "../../assets/la-at-night.jpg";

const { Content } = Layout;
const { Text } = Typography;

export const About = () => {

  if (isDesktop) {
    return (
      <div style={styles.container}>
        <Header />
        <div
          style={{
            ...styles.background,
            ...{ backgroundImage: `url(${img})` },
          }}
        >
          <Content style={{ ...styles.content }}>
            <iframe
              src="https://player.vimeo.com/video/855546552?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              allow="autoplay; fullscreen; picture-in-picture"
              frameBorder="none"
              style={{ height: "100%" }}
              title="satellite-promo-video"
            ></iframe>
            <script src="https://player.vimeo.com/api/player.js"></script>
            <div
              style={{
                display: "flex",
                height: "100%",
                marginTop: SPACING.XXXL,
                justifyContent: "flex-start",
                flexDirection: "column",
                marginLeft: SPACING.LG,
              }}
            >
              <Text
                style={{
                  fontWeight: "bold",
                  color: "white",
                  fontSize: FONT_SIZE.LG,
                }}
              >
                Satellite connects your community.
              </Text>
              <Text
                style={{
                  color: "white",
                  fontSize: 24,
                  marginTop: SPACING.SM,
                }}
              >
                Want to help? Email will@satellitefriends.com 😊
              </Text>
            </div>
          </Content>
        </div>
      </div>
    );
  }
  return (
    <div style={styles.container}>
      <Header />
      <div
        style={{ ...styles.background, ...{ backgroundImage: `url(${img})` } }}
      >
        <Content style={{ ...styles.content, ...{ flexDirection: "column" } }}>
          <iframe
            src="https://player.vimeo.com/video/855546552?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            allow="autoplay; fullscreen; picture-in-picture"
            frameBorder="none"
            style={{ height: "100%", padding: 0 }}
            title="satellite-promo-video"
          ></iframe>
          <script src="https://player.vimeo.com/api/player.js"></script>
          <div
            style={{
              marginLeft: SPACING.MD,
              marginRight: SPACING.MD,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginTop: SPACING.MD,
            }}
          >
            <Text
              style={{
                fontWeight: "bold",
                color: "white",
                fontSize: FONT_SIZE.MD,
              }}
            >
              Satellite connects your community.
            </Text>
            <Text
              style={{
                color: "white",
                fontSize: 14,
                marginTop: SPACING.SM,
              }}
            >
              Want to help? Email will@satellitefriends.com 😊
            </Text>
          </div>
        </Content>
      </div>
    </div>
  );
};

export default About;

const styles = {
  container: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  background: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    flex: 1,
  },
  titleBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  content: {
    marginTop: SPACING.MD,
    marginBottom: SPACING.MD,
    display: "flex",
    height: `calc(100% - ${SPACING.MD} - ${SPACING.MD}`,
    alignItems: "center",
    justifyContent: "center",
  },
};
