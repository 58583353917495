import React, { useRef, useEffect } from "react";
import {
  UPDATE_ACTIVE_VENUE,
  useSatelliteEnterpriseContext,
} from "../../../../context/SatelliteContext";
import { Typography } from "antd";
import { SPACING } from "../../../../configs/theme";

const { Text } = Typography;

const AutoComplete = () => {
  const {
    state: { activeVenue },
    dispatch,
  } = useSatelliteEnterpriseContext();

  const inputRef = useRef();
  const options = {
    componentRestrictions: { country: "us" },
    fields: ["place_id", "address_components", "geometry", "icon", "name"],
  };

  useEffect(() => {
    //@ts-ignore
    const autocomplete = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );

    autocomplete.addListener("place_changed", () => {
      var place = autocomplete.getPlace();
      if (place.place_id) {
        dispatch({
          type: UPDATE_ACTIVE_VENUE,
          activeVenue: {
            ...activeVenue,
            placeID: place.place_id,
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            address: place.name,
          },
        });
      }
    });
  }, [activeVenue]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Text style={{ fontWeight: "bold" }}>Address:</Text>
      <input
        placeholder="1000 Santa Monica Blvd, etc."
        style={{
          marginBottom: SPACING.SM,
          padding: 8,
          borderColor: "lightgrey",
          border: "1px solid #d9d9d9",
          borderRadius: 6,
        }}
        key={activeVenue?.venueID}
        defaultValue={activeVenue?.address}
        ref={inputRef}
      />
    </div>
  );
};
export default AutoComplete;
