import { InputNumber, Typography } from "antd";
import React from "react";
import { useSatelliteEnterpriseContext } from "../../../context/SatelliteContext";
import { SPACING } from "../../../configs/theme";
import { updateActiveEventValue } from "../../../actions/eventActions";

export const Attendance = () => {
  const {
    state: { activeEvent },
    dispatch,
  } = useSatelliteEnterpriseContext();

  const onChange = () => {};

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
          <Typography.Text style={{ fontWeight: "bold" }}>
            Min attendance:
          </Typography.Text>
          <InputNumber
            min={1}
            style={{ flex: 1, marginRight: SPACING.SM }}
            max={activeEvent?.maximumAttendance || 4}
            defaultValue={activeEvent?.minimumAttendance || 2}
            onChange={(e) =>
              updateActiveEventValue(
                activeEvent,
                dispatch,
                "minimumAttendance",
                e
              )
            }
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
        <Typography.Text style={{ fontWeight: "bold" }}>
            Max attendance:
          </Typography.Text>{" "}
          <InputNumber
            style={{ flex: 1 }}
            min={activeEvent?.minimumAttendance || 1}
            max={50}
            defaultValue={activeEvent?.maximumAttendance || 10}
            onChange={(e) =>
              updateActiveEventValue(
                activeEvent,
                dispatch,
                "maximumAttendance",
                e
              )
            }
          />
        </div>
      </div>
    </div>
  );
};
