import { Firestore } from "firebase/firestore";
import { readNeighborhoods } from "../api/neighborhoodHelpers";
import { DispatchPayload, FETCH_NEIGHBORHOODS } from "../context/SatelliteContext";

export const fetchNeighborhoods = async (
  db: Firestore,
): Promise<DispatchPayload> => {
  return await readNeighborhoods(db)
  .then((neighborhoods) => {
    return { type: FETCH_NEIGHBORHOODS, neighborhoods } as DispatchPayload;
  })
  .catch((_) => {
    return { type: FETCH_NEIGHBORHOODS, neighborhoods: [] };
  });
};
