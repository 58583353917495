import React from "react";
import { City } from "../../../types/City";
import { columns } from "./Columns";
import { Table } from "antd";

interface CitiesTableProps {
  cities: City[];
  onCityClick: (c: City) => void;
}
export const CitiesTable = ({ cities, onCityClick }: CitiesTableProps) => {
  return (
    <Table
      columns={columns}
      dataSource={cities.map((c) => {
        return { ...c, key: c.cityID };
      })}
      pagination={{ pageSize: 5 }}
      rowKey={(record) => record.cityID}
      onRow={(record, _) => {
        return {
          onClick: () => onCityClick(record),
        };
      }}
    />
  );
};
